import pattern from './assets/pattern.svg';

import './App.css';
import 'survey-core/modern.min.css';
import { useTranslation } from 'react-i18next';
import { surveyJson } from './test_1';
import LeftSide from './components/LeftSide';
import Middle from './components/Middle';


function App() {
  return (
    <div className='h-full w-full'>
      <div className='flex flex-col justify-center lg:flex-row'>
      <LeftSide />
      <div className='flex-1'>
        <Middle />
      </div>
        <div className='hidden h-full lg:flex'>
          <img src={pattern} alt='eesti' style={{height: '200%', width: '80%'}}/>
      </div>
      </div>
      
    </div>
  );
}

export default App;
