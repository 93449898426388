export const test2 = {
  title: " ",
  description:{
    et: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!',
    en: 'This language test contains 50 multiple choice questions. The test will help you find the course level you need. If you don’t know the answer, choose the last option!',
    ru: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!'
  },
  pages: [
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas see laud on vaba?",
          title: {
            et: "Kas see laud on vaba?",
            en: "Kas see laud on vaba?",
            ru: "Kas see laud on vaba?",
          },
          choices: [
            {
              value: "Jah, te võite seda süüa!",
              text: {
                et: "Jah, te võite seda süüa!",
                en: "Jah, te võite seda süüa!",
                ru: "Jah, te võite seda süüa!",
              },
            },
            {
              value: "Jah, te võite siia istuda!",
              text: {
                et: "Jah, te võite siia istuda!",
                en: "Jah, te võite siia istuda!",
                ru: "Jah, te võite siia istuda!",
              },
            },
            {
              value: "Jah, te elada ilma suure majata. vaba!",
              text: {
                et: "Jah, te olete vaba!",
                en: "Jah, te olete vaba!",
                ru: "Jah, te olete vaba!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Jah, te võite siia istuda!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Vabandage, kus asub tualett?",
          title: {
            et: "Vabandage, kus asub tualett?",
            en: "Vabandage, kus asub tualett?",
            ru: "Vabandage, kus asub tualett?",
          },
          choices: [
            {
              value: "Siit ei saa süüa!",
              text: {
                et: "Siit ei saa süüa!",
                en: "Siit ei saa süüa!",
                ru: "Siit ei saa süüa!",
              },
            },
            {
              value: "Keldrikorrusel, minge trepist alla!",
              text: {
                et: "Keldrikorrusel, minge trepist alla!",
                en: "Keldrikorrusel, minge trepist alla!",
                ru: "Keldrikorrusel, minge trepist alla!",
              },
            },
            {
              value: "Mulle siin meeldib, jääme siia!",
              text: {
                et: "Mulle siin meeldib, jääme siia!",
                en: "Mulle siin meeldib, jääme siia!",
                ru: "Mulle siin meeldib, jääme siia!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Keldrikorrusel, minge trepist alla!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Milline on Teie haridus?",
          title: {
            et: "Milline on Teie haridus?",
            en: "Milline on Teie haridus?",
            ru: "Milline on Teie haridus?",
          },
          choices: [
            {
              value: "Mul oli keskharidus.",
              text: {
                et: "Mul oli keskharidus.",
                en: "Mul oli keskharidus.",
                ru: "Mul oli keskharidus.",
              },
            },
            {
              value: "Mul on kõrgharidus.",
              text: {
                et: "Mul on kõrgharidus.",
                en: "Mul on kõrgharidus.",
                ru: "Mul on kõrgharidus.",
              },
            },
            {
              value: "Tal on kõrgharidus.",
              text: {
                et: "Tal on kõrgharidus.",
                en: "Tal on kõrgharidus.",
                ru: "Tal on kõrgharidus.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mul on kõrgharidus.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "С Рождеством!",
          title: {
            et: "С Рождеством!",
            en: "Merry Christmas!",
            ru: "С Рождеством!",
          },
          choices: [
            {
              value: "Head uut aastat!",
              text: {
                et: "Head uut aastat!",
                en: "Head uut aastat!",
                ru: "Head uut aastat!",
              },
            },
            {
              value: "Häid jõule!",
              text: {
                et: "Häid jõule!",
                en: "Häid jõule!",
                ru: "Häid jõule!",
              },
            },
            {
              value: "Head sõbrapäeva!",
              text: {
                et: "Head sõbrapäeva!",
                en: "Head sõbrapäeva!",
                ru: "Head sõbrapäeva!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Häid jõule!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mina lähen kell 8 ... (на работу)",
          title: {
            et: "Mina lähen kell 8 ... (на работу)",
            en: "Mina lähen kell 8 ... (to work)",
            ru: "Mina lähen kell 8 ... (на работу)",
          },
          choices: [
            {
              value: "kooli.",
              text: {
                et: "kooli.",
                en: "kooli.",
                ru: "kooli.",
              },
            },
            {
              value: "tööle.",
              text: {
                et: "tööle.",
                en: "tööle.",
                ru: "tööle.",
              },
            },
            {
              value: "kinno.",
              text: {
                et: "kinno.",
                en: "kinno.",
                ru: "kinno.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "tööle.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas sa tuled eesti keele kursusele ... (с нами)",
          title: {
            et: "Kas sa tuled eesti keele kursusele ... (с с нами",
            en: "Kas sa tuled eesti keele kursusele ... (with us)",
            ru: "Kas sa tuled eesti keele kursusele ... (с нами)",
          },
          choices: [
            {
              value: "minuga koos?",
              text: {
                et: "minuga koos?",
                en: "minuga koos?",
                ru: "minuga koos?",
              },
            },
            {
              value: "koos meiega?",
              text: {
                et: "koos meiega?",
                en: "koos meiega?",
                ru: "koos meiega?",
              },
            },
            {
              value: "temaga koos?",
              text: {
                et: "temaga koos?",
                en: "temaga koos?",
                ru: "temaga koos?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "koos meiega?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Говорите!",
          title: {
            et: "Говорите!",
            en: "Speak! (plural)",
            ru: "Говорите!",
          },
          choices: [
            {
              value: "Räägi!",
              text: {
                et: "Räägi!",
                en: "Räägi!",
                ru: "Räägi!",
              },
            },
            {
              value: "Rääkige!",
              text: {
                et: "Rääkige!",
                en: "Rääkige!",
                ru: "Rääkige!",
              },
            },
            {
              value: "Räägib.",
              text: {
                et: "Räägib.",
                en: "Räägib.",
                ru: "Räägib.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Rääkige!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Вы делали это?",
          title: {
            et: "Вы делали это?",
            en: "Did you do it? (plural)",
            ru: "Вы делали это?",
          },
          choices: [
            {
              value: "Kas sina teed seda?",
              text: {
                et: "Kas sina teed seda?",
                en: "Kas sina teed seda?",
                ru: "Kas sina teed seda?",
              },
            },
            {
              value: "Kas teie tegite seda?",
              text: {
                et: "Kas teie tegite seda?",
                en: "Kas teie tegite seda?",
                ru: "Kas teie tegite seda?",
              },
            },
            {
              value: "Kas teie teete seda?",
              text: {
                et: "Kas teie teete seda?",
                en: "Kas teie teete seda?",
                ru: "Kas teie teete seda?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kas teie tegite seda?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ты не пришел.",
          title: {
            et: "Ты не пришел.",
            en: "You didn’t come. (singular)",
            ru: "Ты не пришел.",
          },
          choices: [
            {
              value: "Ma ei laulnud.",
              text: {
                et: "Ma ei laulnud.",
                en: "Ma ei laulnud.",
                ru: "Ma ei laulnud.",
              },
            },
            {
              value: "Sa ei tulnud.",
              text: {
                et: "Sa ei tulnud.",
                en: "Sa ei tulnud.",
                ru: "Sa ei tulnud.",
              },
            },
            {
              value: "Ta ei näinud.",
              text: {
                et: "Ta ei näinud.",
                en: "Ta ei näinud.",
                ru: "Ta ei näinud.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Sa ei tulnud.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta peab ... (Он должен смотреть.)",
          title: {
            et: "Ta peab ... (Он должен смотреть.)",
            en: "Ta peab ... (He has to look.)",
            ru: "Ta peab ... (Он должен смотреть.)",
          },
          choices: [
            {
              value: "vaadata.",
              text: {
                et: "vaadata.",
                en: "vaadata.",
                ru: "vaadata.",
              },
            },
            {
              value: "vaatama.",
              text: {
                et: "vaatama.",
                en: "vaatama.",
                ru: "vaatama.",
              },
            },
            {
              value: "vaatan.",
              text: {
                et: "vaatan.",
                en: "vaatan.",
                ru: "vaatan.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "vaatama.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Palun oodake, ma ei saa praegu ... (говорить)",
          title: {
            et: "Palun oodake, ma ei saa praegu ... (говорить)",
            en: "Palun oodake, ma ei saa praegu ... (to speak)",
            ru: "Palun oodake, ma ei saa praegu ... (говорить)",
          },
          choices: [
            {
              value: "rääkima.",
              text: {
                et: "rääkima.",
                en: "rääkima.",
                ru: "rääkima.",
              },
            },
            {
              value: "rääkida.",
              text: {
                et: "rääkida.",
                en: "rääkida.",
                ru: "rääkida.",
              },
            },
            {
              value: "räägin.",
              text: {
                et: "räägin.",
                en: "räägin.",
                ru: "räägin.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "rääkida.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma käin iga päev ... (Я хожу каждый день на работу.)",
          title: {
            et: "Ma käin iga päev ... (Я хожу каждый день на работу.)",
            en: "Ma käin ... (I go to work.)",
            ru: "Ma käin iga päev ... (Я хожу каждый день на работу.)",
          },
          choices: [
            {
              value: "tööle.",
              text: {
                et: "tööle.",
                en: "tööle.",
                ru: "tööle.",
              },
            },
            {
              value: "tööl.",
              text: {
                et: "tööl.",
                en: "tööl.",
                ru: "tööl.",
              },
            },
            {
              value: "töölt.",
              text: {
                et: "töölt.",
                en: "töölt.",
                ru: "töölt.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "tööl.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Millal sa ... minu juurde? (Когда ты придешь ко мне?)",
          title: {
            et: "Millal sa ... minu juurde? (Когда ты придешь ко мне?)",
            en: "Millal sa ... minu juurde? (to come)",
            ru: "Millal sa ... minu juurde? (Когда ты придешь ко мне?)",
          },
          choices: [
            {
              value: "käid",
              text: {
                et: "käid",
                en: "käid",
                ru: "käid",
              },
            },
            {
              value: "tuled",
              text: {
                et: "tuled",
                en: "tuled",
                ru: "tuled",
              },
            },
            {
              value: "lähed",
              text: {
                et: "lähed",
                en: "lähed",
                ru: "lähed",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "tuled",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Сколько у тебя комнат в квартире?",
          title: {
            et: "Сколько у тебя комнат в квартире?",
            en: "How big is your flat? (plural)",
            ru: "Сколько у тебя комнат в квартире?",
          },
          choices: [
            {
              value: "Kui suur on teie auto?",
              text: {
                et: "Kui suur on teie auto?",
                en: "Kui suur on teie auto?",
                ru: "Kui suur on teie auto?",
              },
            },
            {
              value: "Kui suur on teie korter?",
              text: {
                et: "Kui suur on teie korter?",
                en: "Kui suur on teie korter?",
                ru: "Kui suur on teie korter?",
              },
            },
            {
              value: "Kui suur on teie tuba?",
              text: {
                et: "Kui suur on teie tuba?",
                en: "Kui suur on teie tuba?",
                ru: "Kui suur on teie tuba?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kui suur on teie korter?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma elan ... ... ... (в большом старом доме)",
          title: {
            et: "Ma elan ... ... ... (в большом старом доме)",
            en: "Ma elan ... ... ... (in a big old house)",
            ru: "Ma elan ... ... ... (в большом старом доме)",
          },
          choices: [
            {
              value: "suurel vanal majal.",
              text: {
                et: "suurel vanal majal.",
                en: "suurel vanal majal.",
                ru: "suurel vanal majal.",
              },
            },
            {
              value: "suures vanas majas.",
              text: {
                et: "suures vanas majas.",
                en: "suures vanas majas.",
                ru: "suures vanas majas.",
              },
            },
            {
              value: "suurele vanale majale.",
              text: {
                et: "suurele vanale majale.",
                en: "suurele vanale majale.",
                ru: "suurele vanale majale.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "suures vanas majas.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Me ei saa ... ... ... ... (Мы не можем жить без большого дома.)",
          title: {
            et: "Me ei saa ... ... ... ... (Мы не можем жить без большого дома.)",
            en: "Me ei saa ... ... ... ... (We cannot live without a big house.)",
            ru: "Me ei saa ... ... ... ... (Мы не можем жить без большого дома.)",
          },
          choices: [
            {
              value: "elama ilma suure majata.",
              text: {
                et: "elama ilma suure majata.",
                en: "elama ilma suure majata.",
                ru: "elama ilma suure majata.",
              },
            },
            {
              value: "elada ilma suure majata.",
              text: {
                et: "elada ilma suure majata.",
                en: "elada ilma suure majata.",
                ru: "elada ilma suure majata.",
              },
            },
            {
              value: "elada koos suure majaga.",
              text: {
                et: "elada koos suure majaga.",
                en: "elada koos suure majaga.",
                ru: "elada koos suure majaga.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "elada ilma suure majata.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta elab ... korteris. (Он живет в трехкомнатной квартире.)",
          title: {
            et: "Ta elab ... korteris. (Он живет в трехкомнатной квартире.)",
            en: "Ta elab ... korteris. (He lives in a three-room apartment.)",
            ru: "Ta elab ... korteris. (Он живет в трехкомнатной квартире.)",
          },
          choices: [
            {
              value: "ühetoalises",
              text: {
                et: "ühetoalises",
                en: "ühetoalises",
                ru: "ühetoalises",
              },
            },
            {
              value: "kolmetoalises",
              text: {
                et: "kolmetoalises",
                en: "kolmetoalises",
                ru: "kolmetoalises",
              },
            },
            {
              value: "neljatoalises",
              text: {
                et: "neljatoalises",
                en: "neljatoalises",
                ru: "neljatoalises",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kolmetoalises",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Minu maja on restorani ... (Мой дом рядом с рестораном.)",
          title: {
            et: "Minu maja on restorani ... (Мой дом рядом с рестораном.)",
            en: "Minu maja on restorani ... (My house is close to a restaurant.)",
            ru: "Minu maja on restorani ... (Мой дом рядом с рестораном.)",
          },
          choices: [
            {
              value: "kõrval.",
              text: {
                et: "kõrval.",
                en: "kõrval.",
                ru: "kõrval.",
              },
            },
            {
              value: "lähedal.",
              text: {
                et: "lähedal.",
                en: "lähedal.",
                ru: "lähedal.",
              },
            },
            {
              value: "ääres.",
              text: {
                et: "ääres.",
                en: "ääres.",
                ru: "ääres.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "lähedal.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma elan ... korrusel. (Я живу на третьем этаже.)",
          title: {
            et: "Ma elan ... korrusel. (Я живу на третьем этаже.)",
            en: "Me elame ... korrusel. (We live on the third floor.)",
            ru: "Ma elan ... korrusel. (Я живу на третьем этаже.)",
          },
          choices: [
            {
              value: "teisel",
              text: {
                et: "teisel",
                en: "teisel",
                ru: "teisel",
              },
            },
            {
              value: "kolmandal",
              text: {
                et: "kolmandal",
                en: "kolmandal",
                ru: "kolmandal",
              },
            },
            {
              value: "kuuendal",
              text: {
                et: "kuuendal",
                en: "kuuendal",
                ru: "kuuendal",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kolmandal",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kui palju maksab Paul vee ja kanalisatsiooni eest kuus ? Ta maksab 26.48. (Сколько платит Павел за воду и канализацию в месяц?)",
          title: {
            et: "Kui palju maksab Paul vee ja kanalisatsiooni eest kuus ? Ta maksab 26.48. (Сколько платит Павел за воду и канализацию в месяц?)",
            en: "Kui palju maksab Paul vee ja kanalisatsiooni eest kuus ? Ta maksab 26.48. (How much pays Paul for water and sewage per month?)",
            ru: "Kui palju maksab Paul vee ja kanalisatsiooni eest kuus ? Ta maksab 26.48. (Сколько платит Павел за воду и канализацию в месяц?)",
          },
          choices: [
            {
              value: "kakskümmend kuus eurot ja viiskümmend kaheksa senti.",
              text: {
                et: "kakskümmend kuus eurot ja viiskümmend kaheksa senti.",
                en: "kakskümmend kuus eurot ja viiskümmend kaheksa senti.",
                ru: "kakskümmend kuus eurot ja viiskümmend kaheksa senti.",
              },
            },
            {
              value: "kakskümmend kuus eurot ja nelikümmend kaheksa senti.",
              text: {
                et: "kakskümmend kuus eurot ja nelikümmend kaheksa senti.",
                en: "kakskümmend kuus eurot ja nelikümmend kaheksa senti.",
                ru: "kakskümmend kuus eurot ja nelikümmend kaheksa senti.",
              },
            },
            {
              value: "kakskümmend viis eurot ja nelikümmend kolm senti.",
              text: {
                et: "kakskümmend viis eurot ja nelikümmend kolm senti.",
                en: "kakskümmend viis eurot ja nelikümmend kolm senti.",
                ru: "kakskümmend viis eurot ja nelikümmend kolm senti.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kakskümmend kuus eurot ja nelikümmend kaheksa senti.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Meil on ... rent. (У нас низкая аренда.)",
          title: {
            et: "Meil on ... rent. (У нас низкая аренда.)",
            en: "Meil on ... rent. (We have a low rent.)",
            ru: "Meil on ... rent. (У нас низкая аренда.)",
          },
          choices: [
            {
              value: "kõrge",
              text: {
                et: "kõrge",
                en: "kõrge",
                ru: "kõrge",
              },
            },
            {
              value: "keskmine",
              text: {
                et: "keskmine",
                en: "keskmine",
                ru: "keskmine",
              },
            },
            {
              value: "madal",
              text: {
                et: "madal",
                en: "madal",
                ru: "madal",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "madal",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Helistan Teile ajalehekuulutuse ... (Я звоню вам по поводу объявления в газете.)",
          title: {
            et: "Helistan Teile ajalehekuulutuse ... (Я звоню вам по поводу объявления в газете.)",
            en: "Helistan Teile ajalehekuulutuse ... (I’m calling you regarding the newspaper ad.)",
            ru: "Helistan Teile ajalehekuulutuse ... (Я звоню вам по поводу объявления в газете.)",
          },
          choices: [
            {
              value: "juurde.",
              text: {
                et: "juurde.",
                en: "juurde.",
                ru: "juurde.",
              },
            },
            {
              value: "üle.",
              text: {
                et: "üle.",
                en: "üle.",
                ru: "üle.",
              },
            },
            {
              value: "pärast.",
              text: {
                et: "pärast.",
                en: "pärast.",
                ru: "pärast.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "pärast.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kui suured on ... (На сколько большие коммунальные платежи?)",
          title: {
            et: "Kui suured on ... (На сколько большие коммунальные платежи?)",
            en: "Kui suured on ... (How large are the utility payments?)",
            ru: "Kui suured on ... (На сколько большие коммунальные платежи?)",
          },
          choices: [
            {
              value: "prügiveokulud?",
              text: {
                et: "prügiveokulud?",
                en: "prügiveokulud?",
                ru: "prügiveokulud?",
              },
            },
            {
              value: "elektrikulud?",
              text: {
                et: "elektrikulud?",
                en: "elektrikulud?",
                ru: "elektrikulud?",
              },
            },
            {
              value: "kommunaalkulud?",
              text: {
                et: "kommunaalkulud?",
                en: "kommunaalkulud?",
                ru: "kommunaalkulud?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kommunaalkulud?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Tore sind näha! Me pole nii ammu kohtunud!",
          title: {
            et: "Tore sind näha! Me pole nii ammu kohtunud!",
            en: "Tore sind näha! Me pole nii ammu kohtunud!",
            ru: "Tore sind näha! Me pole nii ammu kohtunud!",
          },
          choices: [
            {
              value: "Jah, ma olen väga väsinud!",
              text: {
                et: "Jah, ma olen väga väsinud!",
                en: "Jah, ma olen väga väsinud!",
                ru: "Jah, ma olen väga väsinud!",
              },
            },
            {
              value: "Jah, tore on jälle kohtuda!",
              text: {
                et: "Jah, tore on jälle kohtuda!",
                en: "Jah, tore on jälle kohtuda!",
                ru: "Jah, tore on jälle kohtuda!",
              },
            },
            {
              value: "Jah, ma lähen koju!",
              text: {
                et: "Jah, ma lähen koju!",
                en: "Jah, ma lähen koju!",
                ru: "Jah, ma lähen koju!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Jah, tore on jälle kohtuda!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Siin saate käsi ..., kui soovite! (Здесь вы можете помыть руки, если хотите!)",
          title: {
            et: "Siin saate käsi ..., kui soovite! (Здесь вы можете помыть руки, если хотите!)",
            en: "Siin saate käsi ..., kui soovite! (Here you can wash your hands if you wish!)",
            ru: "Siin saate käsi ..., kui soovite! (Здесь вы можете помыть руки, если хотите!)",
          },
          choices: [
            {
              value: "plaksutada",
              text: {
                et: "plaksutada",
                en: "plaksutada",
                ru: "plaksutada",
              },
            },
            {
              value: "pesta",
              text: {
                et: "pesta",
                en: "pesta",
                ru: "pesta",
              },
            },
            {
              value: "pesema",
              text: {
                et: "pesema",
                en: "pesema",
                ru: "pesema",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "pesta",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mida ma võin ... ... (Что я могу вам предложить?)",
          title: {
            et: "Mida ma võin ... ... (Что я могу вам предложить?)",
            en: "Mida ma võin ... ... (What can I offer you?)",
            ru: "Mida ma võin ... ... (Что я могу вам предложить?)",
          },
          choices: [
            {
              value: "sulle laulda?",
              text: {
                et: "sulle laulda?",
                en: "sulle laulda?",
                ru: "sulle laulda?",
              },
            },
            {
              value: "sulle pakkuda?",
              text: {
                et: "sulle pakkuda?",
                en: "sulle pakkuda?",
                ru: "sulle pakkuda?",
              },
            },
            {
              value: "sulle  rääkida?",
              text: {
                et: "sulle  rääkida?",
                en: "sulle  rääkida?",
                ru: "sulle  rääkida?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "sulle pakkuda?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mark ... ... ..., ... ... (Марк сегодня не приедет, он болен.)",
          title: {
            et: "Mark ... ... ..., ... ... (Марк сегодня не приедет, он болен.)",
            en: "Mark ... ... ..., ... ... (Mark is not coming today, he is ill.)",
            ru: "Mark ... ... ..., ... ... (Марк сегодня не приедет, он болен.)",
          },
          choices: [
            {
              value: "ei lähe täna, ta on terve.",
              text: {
                et: "ei lähe täna, ta on terve.",
                en: "ei lähe täna, ta on terve.",
                ru: "ei lähe täna, ta on terve.",
              },
            },
            {
              value: "ei tule täna, ta on haige.",
              text: {
                et: "ei tule täna, ta on haige.",
                en: "ei tule täna, ta on haige.",
                ru: "ei tule täna, ta on haige.",
              },
            },
            {
              value: "ei tule täna, ta oli haige.",
              text: {
                et: "ei tule täna, ta oli haige.",
                en: "ei tule täna, ta oli haige.",
                ru: "ei tule täna, ta oli haige.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ei tule täna, ta on haige.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "On teil midagi peavalu ... (Есть у вас что-нибудь против головной боли?)",
          title: {
            et: "On teil midagi peavalu ... (Есть у вас что-нибудь против головной боли?)",
            en: "On teil midagi peavalu ... (Do you have anything against headache?)",
            ru: "On teil midagi peavalu ... (Есть у вас что-нибудь против головной боли?)",
          },
          choices: [
            {
              value: "poolt?",
              text: {
                et: "poolt?",
                en: "poolt?",
                ru: "poolt?",
              },
            },
            {
              value: "vastu?",
              text: {
                et: "vastu?",
                en: "vastu?",
                ru: "vastu?",
              },
            },
            {
              value: "juures?",
              text: {
                et: "juures?",
                en: "juures?",
                ru: "juures?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "vastu?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Võtke rohtu söögi ...( Принимайте лекарство во время еды!)",
          title: {
            et: "Võtke rohtu söögi ...( Принимайте лекарство во время еды!)",
            en: "Võtke rohtu söögi ...(Take the medcine at mealtime!)",
            ru: "Võtke rohtu söögi ...( Принимайте лекарство во время еды!)",
          },
          choices: [
            {
              value: "pärast!",
              text: {
                et: "pärast!",
                en: "pärast!",
                ru: "pärast!",
              },
            },
            {
              value: "ajal!",
              text: {
                et: "ajal!",
                en: "ajal!",
                ru: "ajal!",
              },
            },
            {
              value: "enne!",
              text: {
                et: "enne!",
                en: "enne!",
                ru: "enne!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ajal!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Enne ravimi kasutamist ... hoolikalt pakendi infolehte! (Перед использованием внимательно прочтите информационный буклет для пациента!)",
          title: {
            et: "Enne ravimi kasutamist ... hoolikalt pakendi infolehte! (Перед использованием внимательно прочтите информационный буклет для пациента!)",
            en: "Enne ravimi kasutamist ... hoolikalt pakendi infolehte! (Read the patient information leaflet carefully before use!) (plural)",
            ru: "Enne ravimi kasutamist ... hoolikalt pakendi infolehte! (Перед использованием внимательно прочтите информационный буклет для пациента!)",
          },
          choices: [
            {
              value: "kirjutage",
              text: {
                et: "kirjutage",
                en: "kirjutage",
                ru: "kirjutage",
              },
            },
            {
              value: "lugege",
              text: {
                et: "lugege",
                en: "lugege",
                ru: "lugege",
              },
            },
            {
              value: "sööge",
              text: {
                et: "sööge",
                en: "sööge",
                ru: "sööge",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "lugege",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma hakkan ... ... ... homme. (Я собираюсь прочитать эту книгу завтра.)",
          title: {
            et: "Ma hakkan ... ... ... homme. (Я собираюсь прочитать эту книгу завтра.)",
            en: "Ma hakkan ... ... ... homme. (I will read this book tomorrow.)",
            ru: "Ma hakkan ... ... ... homme. (Я собираюсь прочитать эту книгу завтра.)",
          },
          choices: [
            {
              value: "lugeda seda raamatut",
              text: {
                et: "lugeda seda raamatut",
                en: "lugeda seda raamatut",
                ru: "lugeda seda raamatut",
              },
            },
            {
              value: "lugema seda raamatut",
              text: {
                et: "lugema seda raamatut",
                en: "lugema seda raamatut",
                ru: "lugema seda raamatut",
              },
            },
            {
              value: "lugema seda raamatu",
              text: {
                et: "lugema seda raamatu",
                en: "lugema seda raamatu",
                ru: "lugema seda raamatu",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "lugema seda raamatut",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma ei ... neid raamatuid. (Я не читал эти книги.)",
          title: {
            et: "Ma ei ... neid raamatuid. (Я не читал эти книги.)",
            en: "Ma ei ... neid raamatuid läbi. (I didn’t read these books (through).)",
            ru: "Ma ei ... neid raamatuid. (Я не читал эти книги.)",
          },
          choices: [
            {
              value: "lugesin",
              text: {
                et: "lugesin",
                en: "lugesin",
                ru: "lugesin",
              },
            },
            {
              value: "lugenud",
              text: {
                et: "lugenud",
                en: "lugenud",
                ru: "lugenud",
              },
            },
            {
              value: "lugeda",
              text: {
                et: "lugeda",
                en: "lugeda",
                ru: "lugeda",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "lugenud",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas pulber on ... kui tabletid? (Порошок дороже таблеток?)",
          title: {
            et: "Kas pulber on ... kui tabletid? (Порошок дороже таблеток?)",
            en: "Kas pulber on ... kui tabletid? (Is the powder more expensive than the tablets?)",
            ru: "Kas pulber on ... kui tabletid? (Порошок дороже таблеток?)",
          },
          choices: [
            {
              value: "odavam",
              text: {
                et: "odavam",
                en: "odavam",
                ru: "odavam",
              },
            },
            {
              value: "kallim",
              text: {
                et: "kallim",
                en: "kallim",
                ru: "kallim",
              },
            },
            {
              value: "magusam",
              text: {
                et: "magusam",
                en: "magusam",
                ru: "magusam",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kallim",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Millal dr Kask patsiente vastu ... (Когда доктор Каск принимает пациентов?)",
          title: {
            et: "Millal dr Kask patsiente vastu ... (Когда доктор Каск принимает пациентов?)",
            en: "Millal dr Kask patsiente vastu ... (When does Dr. Kask receive patients?)",
            ru: "Millal dr Kask patsiente vastu ... (Когда доктор Каск принимает пациентов?)",
          },
          choices: [
            {
              value: "tuleb?",
              text: {
                et: "tuleb?",
                en: "tuleb?",
                ru: "tuleb?",
              },
            },
            {
              value: "võtab?",
              text: {
                et: "võtab?",
                en: "võtab?",
                ru: "võtab?",
              },
            },
            {
              value: "töötab?",
              text: {
                et: "töötab?",
                en: "töötab?",
                ru: "töötab?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "võtab?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta võtab patsiente vastu ... (по понедельникам)",
          title: {
            et: "Ta võtab patsiente vastu ... (по понедельникам)",
            en: "Ta võtab patsiente vastu ... (on Mondays)",
            ru: "Ta võtab patsiente vastu ... (по понедельникам)",
          },
          choices: [
            {
              value: "esmaspäeviti.",
              text: {
                et: "esmaspäeviti.",
                en: "esmaspäeviti.",
                ru: "esmaspäeviti.",
              },
            },
            {
              value: "õhtupoole.",
              text: {
                et: "õhtupoole.",
                en: "õhtupoole.",
                ru: "õhtupoole.",
              },
            },
            {
              value: "homme pärast lõunat.",
              text: {
                et: "homme pärast lõunat.",
                en: "homme pärast lõunat.",
                ru: "homme pärast lõunat.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "esmaspäeviti.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kohtume ... (Встретимся в феврале!)",
          title: {
            et: "Kohtume ... (Встретимся в феврале!)",
            en: "Kohtume ... (Let’s meet in February!)",
            ru: "Kohtume ... (Встретимся в феврале!)",
          },
          choices: [
            {
              value: "veebruaril!",
              text: {
                et: "veebruaril!",
                en: "veebruaril!",
                ru: "veebruaril!",
              },
            },
            {
              value: "veebruaris!",
              text: {
                et: "veebruaris!",
                en: "veebruaris!",
                ru: "veebruaris!",
              },
            },
            {
              value: "veebruarile!",
              text: {
                et: "veebruarile!",
                en: "veebruarile!",
                ru: "veebruarile!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "veebruaris!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Tema sünnipäev on 10.02. (У нее день рождения десятого февраля.)",
          title: {
            et: "Tema sünnipäev on 10.02. (У нее день рождения десятого февраля.)",
            en: "Tema sünnipäev on 10.02. (Her birthday is on the tenth of February.)",
            ru: "Tema sünnipäev on 10.02. (У нее день рождения десятого февраля.)",
          },
          choices: [
            {
              value: "kümnendas veebruaris.",
              text: {
                et: "kümnendas veebruaris.",
                en: "kümnendas veebruaris.",
                ru: "kümnendas veebruaris.",
              },
            },
            {
              value: "kümnendale veebruarile.",
              text: {
                et: "kümnendale veebruarile.",
                en: "kümnendale veebruarile.",
                ru: "kümnendale veebruarile.",
              },
            },
            {
              value: "kümnendal veebruaril.",
              text: {
                et: "kümnendal veebruaril.",
                en: "kümnendal veebruaril.",
                ru: "kümnendal veebruaril.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kümnendal veebruaril.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma külastan teid ... (Я с удовольствием приеду к вам!)",
          title: {
            et: "Ma külastan teid ... (Я с удовольствием приеду к вам!)",
            en: "Ma külastan sind ... (I will visit you with pleasure!)",
            ru: "Ma külastan teid ... (Я с удовольствием приеду к вам!)",
          },
          choices: [
            {
              value: "kurvastusega!",
              text: {
                et: "kurvastusega!",
                en: "kurvastusega!",
                ru: "kurvastusega!",
              },
            },
            {
              value: "koos emaga!",
              text: {
                et: "koos emaga!",
                en: "koos emaga!",
                ru: "koos emaga!",
              },
            },
            {
              value: "rõõmuga!",
              text: {
                et: "rõõmuga!",
                en: "rõõmuga!",
                ru: "rõõmuga!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "rõõmuga!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma lähen ... (может быть) nädalavahetusel kontserdile!",
          title: {
            et: "Ma lähen ... (может быть) nädalavahetusel kontserdile!",
            en: "Ma lähen ... (maybe) nädalavahetusel kontserdile!",
            ru: "Ma lähen ... (может быть) nädalavahetusel kontserdile!",
          },
          choices: [
            {
              value: "kindlasti",
              text: {
                et: "kindlasti",
                en: "kindlasti",
                ru: "kindlasti",
              },
            },
            {
              value: "võib-olla",
              text: {
                et: "võib-olla",
                en: "võib-olla",
                ru: "võib-olla",
              },
            },
            {
              value: "meeleldi",
              text: {
                et: "meeleldi",
                en: "meeleldi",
                ru: "meeleldi",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "võib-olla",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "... (конечно) ma helistan neile!",
          title: {
            et: "... (конечно) ma helistan neile!",
            en: "... (of course) ma helistan neile!",
            ru: "... (конечно) ma helistan neile!",
          },
          choices: [
            {
              value: "Tõenäoliselt",
              text: {
                et: "Tõenäoliselt",
                en: "Tõenäoliselt",
                ru: "Tõenäoliselt",
              },
            },
            {
              value: "Muidugi",
              text: {
                et: "Muidugi",
                en: "Muidugi",
                ru: "Muidugi",
              },
            },
            {
              value: "Loomulikult",
              text: {
                et: "Loomulikult",
                en: "Loomulikult",
                ru: "Loomulikult",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "jõuab?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mida Kalevile ... ... peale tööd? (Чем Калев любит заниматься после работы?)",
          title: {
            et: "Mida Kalevile ... ... peale tööd? (Чем Калев любит заниматься после работы?)",
            en: "Mida Kalevile ... ... peale tööd? (What does Kalev like to do after work?)",
            ru: "Mida Kalevile ... ... peale tööd? (Чем Калев любит заниматься после работы?)",
          },
          choices: [
            {
              value: "meeldib tegema",
              text: {
                et: "meeldib tegema",
                en: "meeldib tegema",
                ru: "meeldib tegema",
              },
            },
            {
              value: "meeldib teha",
              text: {
                et: "meeldib teha",
                en: "meeldib teha",
                ru: "meeldib teha",
              },
            },
            {
              value: "meeldib teeb",
              text: {
                et: "meeldib teeb",
                en: "meeldib teeb",
                ru: "meeldib teeb",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "meeldib teha",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Talle meeldib ... ... (Он любит готовить.)",
          title: {
            et: "Talle meeldib ... ... (Он любит готовить.)",
            en: "Talle meeldib ... ... (He likes to cook.)",
            ru: "Talle meeldib ... ... (Он любит готовить.)",
          },
          choices: [
            {
              value: "süüa tuua.",
              text: {
                et: "süüa tuua.",
                en: "süüa tuua.",
                ru: "süüa tuua.",
              },
            },
            {
              value: "süüa teha.",
              text: {
                et: "süüa teha.",
                en: "süüa teha.",
                ru: "süüa teha.",
              },
            },
            {
              value: "süüa kooki.",
              text: {
                et: "süüa kooki.",
                en: "süüa kooki.",
                ru: "süüa kooki.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "süüa teha.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma sain kingituse oma ... ... (Я получил подарок от моей прекрасной подруги.)",
          title: {
            et: "Ma sain kingituse oma ... ... (Я получил подарок от моей прекрасной подруги.)",
            en: "Ma sain kingituse oma ... ... (I got the present from my beautiful friend.)",
            ru: "Ma sain kingituse oma ... ... (Я получил подарок от моей прекрасной подруги.)",
          },
          choices: [
            {
              value: "kaunile sõbrale.",
              text: {
                et: "kaunile sõbrale.",
                en: "kaunile sõbrale.",
                ru: "kaunile sõbrale.",
              },
            },
            {
              value: "kaunilt sõbralt.",
              text: {
                et: "kaunilt sõbralt.",
                en: "kaunilt sõbralt.",
                ru: "kaunilt sõbralt.",
              },
            },
            {
              value: "kaunil sõbral.",
              text: {
                et: "kaunil sõbral.",
                en: "kaunil sõbral.",
                ru: "kaunil sõbral.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kaunilt sõbralt.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Need ... ... ... (Они идеально подходят вам!)",
          title: {
            et: "Need ... ... ... (Они идеально подходят вам!)",
            en: "Need ... ... ... (These fit you perfectly!)",
            ru: "Need ... ... ... (Они идеально подходят вам!)",
          },
          choices: [
            {
              value: "sobib teile hästi!",
              text: {
                et: "sobib teile hästi!",
                en: "sobib teile hästi!",
                ru: "sobib teile hästi!",
              },
            },
            {
              value: "sobivad teile ideaalselt!",
              text: {
                et: "sobivad teile ideaalselt!",
                en: "sobivad teile ideaalselt!",
                ru: "sobivad teile ideaalselt!",
              },
            },
            {
              value: "sobivad teile halvasti!",
              text: {
                et: "sobivad teile halvasti!",
                en: "sobivad teile halvasti!",
                ru: "sobivad teile halvasti!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "sobivad teile ideaalselt!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mis ... sa kannad? (Какой размер ты носишь?)",
          title: {
            et: "Mis ... sa kannad? (Какой размер ты носишь?)",
            en: "Mis ... te kannate? (Which size do you wear?)",
            ru: "Mis ... sa kannad? (Какой размер ты носишь?)",
          },
          choices: [
            {
              value: "suurus",
              text: {
                et: "suurus",
                en: "suurus",
                ru: "suurus",
              },
            },
            {
              value: "suurust",
              text: {
                et: "suurust",
                en: "suurust",
                ru: "suurust",
              },
            },
            {
              value: "suuruse",
              text: {
                et: "suuruse",
                en: "suuruse",
                ru: "suuruse",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "suurust",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Сколько стоила ваша поездка?",
          title: {
            et: "Сколько стоила ваша поездка?",
            en: "How much did your trip cost? (singular)",
            ru: "Сколько стоила ваша поездка?",
          },
          choices: [
            {
              value: "Kui palju sinu reis maksab?",
              text: {
                et: "Kui palju sinu reis maksab?",
                en: "Kui palju sinu reis maksab?",
                ru: "Kui palju sinu reis maksab?",
              },
            },
            {
              value: "Kui palju sinu reis maksis?",
              text: {
                et: "Kui palju sinu reis maksis?",
                en: "Kui palju sinu reis maksis?",
                ru: "Kui palju sinu reis maksis?",
              },
            },
            {
              value: "Kui kaua sinu reis kestis?",
              text: {
                et: "Kui kaua sinu reis kestis?",
                en: "Kui kaua sinu reis kestis?",
                ru: "Kui kaua sinu reis kestis?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kui palju sinu reis maksis?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma ... teil sõita Kreekasse! (Рекомендую поехать в Грецию!)",
          title: {
            et: "Ma ... teil sõita Kreekasse! (Рекомендую поехать в Грецию!)",
            en: "Ma ... teil sõita Kreekasse! (I recommend you to go to Greece!)",
            ru: "Ma ... teil sõita Kreekasse! (Рекомендую поехать в Грецию!)",
          },
          choices: [
            {
              value: "soovin",
              text: {
                et: "soovin",
                en: "soovin",
                ru: "soovin",
              },
            },
            {
              value: "soovitan",
              text: {
                et: "soovitan",
                en: "soovitan",
                ru: "soovitan",
              },
            },
            {
              value: "soovitasin",
              text: {
                et: "soovitasin",
                en: "soovitasin",
                ru: "soovitasin",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "soovitan",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Puhub ... .... (Дует слабый ветер.)",
          title: {
            et: "Puhub ... .... (Дует слабый ветер.)",
            en: "Puhub ... .... (Weak wind is blowing.)",
            ru: "Puhub ... .... (Дует слабый ветер.)",
          },
          choices: [
            {
              value: "tugev tuul.",
              text: {
                et: "tugev tuul.",
                en: "tugev tuul.",
                ru: "tugev tuul.",
              },
            },
            {
              value: "nõrk tuul.",
              text: {
                et: "nõrk tuul.",
                en: "nõrk tuul.",
                ru: "nõrk tuul.",
              },
            },
            {
              value: "külm tuul.",
              text: {
                et: "külm tuul.",
                en: "külm tuul.",
                ru: "külm tuul.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "nõrk tuul.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Lapsed ... koolis. (дети учились в школе.)",
          title: {
            et: "Lapsed ... koolis. (дети учились в школе.)",
            en: "Lapsed ... koolis. (Children studied at school.)",
            ru: "Lapsed ... koolis. (дети учились в школе.)",
          },
          choices: [
            {
              value: "õpivad ",
              text: {
                et: "õpivad ",
                en: "õpivad ",
                ru: "õpivad ",
              },
            },
            {
              value: "õppisid",
              text: {
                et: "õppisid",
                en: "õppisid",
                ru: "õppisid",
              },
            },
            {
              value: "hakkavad õppima",
              text: {
                et: "hakkavad õppima",
                en: "hakkavad õppima",
                ru: "hakkavad õppima",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "õppisid",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Koolis ... (в школе изучали.)",
          title: {
            et: "Koolis ... (в школе изучали.)",
            en: "Koolis ... (It was studied at school.)",
            ru: "Koolis ... (в школе изучали.)",
          },
          choices: [
            {
              value: "õpitakse.",
              text: {
                et: "õpitakse.",
                en: "õpitakse.",
                ru: "õpitakse.",
              },
            },
            {
              value: "õpiti.",
              text: {
                et: "õpiti.",
                en: "õpiti.",
                ru: "õpiti.",
              },
            },
            {
              value: "õppima.",
              text: {
                et: "õppima.",
                en: "õppima.",
                ru: "õppima.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "õpiti.",
        },
      ],
    },
  ],

  completedHtml:
    "You got {correctAnswers} answers out of {questionCount} questions",
};
