export const test1 = {
  title: " ",
  description:{
    et: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!',
    en: 'This language test contains 50 multiple choice questions. The test will help you find the course level you need. If you don’t know the answer, choose the last option!',
    ru: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!'
  },
  pages: [
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Доброе утро!",
          title: {
            et: "Доброе утро!",
            en: "Good morning!",
            ru: "Доброе утро!",
          },
          choices: [
            {
              value: "Kõike head!",
              text: {
                et: "Kõike head!",
                en: "Kõike head!",
                ru: "Kõike head!",
              },
            },
            {
              value: "Tere päevast!",
              text: {
                et: "Tere õhtust!",
                en: "Tere õhtust!",
                ru: "Tere õhtust!",
              },
            },
            {
              value: "Tere hommikust!",
              text: {
                et: "Tere hommikust!",
                en: "Tere hommikust!",
                ru: "Tere hommikust!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Tere hommikust!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "До свидания!",
          title: {
            et: "До свидания!",
            en: "Goodbye!",
            ru: "До свидания!",
          },
          choices: [
            {
              value: "Kõike head!",
              text: {
                et: "Kõike head!",
                en: "Kõike head!",
                ru: "Kõike head!",
              },
            },
            {
              value: "Head aega!",
              text: {
                et: "Head aega!",
                en: "Head aega!",
                ru: "Head aega!",
              },
            },
            {
              value: "Kohtumiseni!",
              text: {
                et: "Kohtumiseni!",
                en: "Kohtumiseni!",
                ru: "Kohtumiseni!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Head aega!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mina ... Toomas. (Я Томас.)",
          title: {
            et: "Mina ... Toomas. (Я Томас.)",
            en: "Mina ... Toomas. (I am Thomas.)",
            ru: "Mina ... Toomas. (Я Томас.)",
          },
          choices: [
            {
              value: "oleme",
              text: {
                et: "oleme",
                en: "oleme",
                ru: "oleme",
              },
            },
            {
              value: "olen",
              text: {
                et: "olen",
                en: "olen",
                ru: "olen",
              },
            },
            {
              value: "olete",
              text: {
                et: "olete",
                en: "olete",
                ru: "olete",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "olen",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "... on ema. (Она мать.)",
          title: {
            et: "... on ema. (Она мать.)",
            en: "... on ema. (She is a mother.)",
            ru: "... on ema. (Она мать.)",
          },
          choices: [
            {
              value: "Sina",
              text: {
                et: "Sina",
                en: "Sina",
                ru: "Sina",
              },
            },
            {
              value: "Tema",
              text: {
                et: "Tema",
                en: "Tema",
                ru: "Tema",
              },
            },
            {
              value: "Teie",
              text: {
                et: "Teie",
                en: "Teie",
                ru: "Teie",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Tema",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Te ... õpilased. (Вы ученики.)",
          title: {
            et: "Te ... õpilased. (Вы ученики.)",
            en: "Te ... õpilased. (You are the pupils.)",
            ru: "Te ... õpilased. (Вы ученики.)",
          },
          choices: [
            {
              value: "on",
              text: {
                et: "on",
                en: "on",
                ru: "on",
              },
            },
            {
              value: "olete",
              text: {
                et: "olete",
                en: "olete",
                ru: "olete",
              },
            },
            {
              value: "oleme",
              text: {
                et: "oleme",
                en: "oleme",
                ru: "oleme",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "olete",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma ... ... naine. (Я не женщина.)",
          title: {
            et: "Ma ... ... naine. (Я не женщина.)",
            en: "Ma ... ... naine. (I am not a woman.)",
            ru: "Ma ... ... naine. (Я не женщина.)",
          },
          choices: [
            {
              value: "ei saa",
              text: {
                et: "ei saa",
                en: "ei saa",
                ru: "ei saa",
              },
            },
            {
              value: "ei ole",
              text: {
                et: "ei ole",
                en: "ei ole",
                ru: "ei ole",
              },
            },
            {
              value: "ei taha",
              text: {
                et: "ei taha",
                en: "ei taha",
                ru: "ei taha",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ei ole",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Извините!",
          title: {
            et: "Извините!",
            en: "Excuse me!",
            ru: "Извините!",
          },
          choices: [
            {
              value: "Võta heaks!",
              text: {
                et: "Võta heaks!",
                en: "Võta heaks!",
                ru: "Võta heaks!",
              },
            },
            {
              value: "Vabandage!",
              text: {
                et: "Vabandage!",
                en: "Vabandage!",
                ru: "Vabandage!",
              },
            },
            {
              value: "Pole midagi!",
              text: {
                et: "Pole midagi!",
                en: "Pole midagi!",
                ru: "Pole midagi!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Vabandage!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta ... (Он говорит.)",
          title: {
            et: "Ta ... (Он говорит.)",
            en: "Ta ... (He speaks.)",
            ru: "Ta ... (Он говорит.)",
          },
          choices: [
            {
              value: "räägime.",
              text: {
                et: "räägime.",
                en: "räägime.",
                ru: "räägime.",
              },
            },
            {
              value: "räägib.",
              text: {
                et: "räägib.",
                en: "räägib.",
                ru: "räägib.",
              },
            },
            {
              value: "räägivad.",
              text: {
                et: "räägivad.",
                en: "räägivad.",
                ru: "räägivad.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "räägib.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas sa räägid eesti keelt? (Ты говоришь на эстонском? Да я немного говорю.)",
          title: {
            et: "Kas sa räägid eesti keelt? (Ты говоришь на эстонском? Да я немного говорю.)",
            en: "Kas te räägite eesti keelt? (Do you speak Estonian? Yes, I speak a little.)",
            ru: "Kas sa räägid eesti keelt? (Ты говоришь на эстонском? Да я немного говорю.)",
          },
          choices: [
            {
              value: "Jah, ma räägin vabalt.",
              text: {
                et: "Jah, ma räägin vabalt.",
                en: "Jah, ma räägin vabalt.",
                ru: "Jah, ma räägin vabalt.",
              },
            },
            {
              value: "Jah, natuke räägin.",
              text: {
                et: "Jah, natuke räägin.",
                en: "Jah, natuke räägin.",
                ru: "Jah, natuke räägin.",
              },
            },
            {
              value: "Ei, ma ei räägi.",
              text: {
                et: "Ei, ma ei räägi.",
                en: "Ei, ma ei räägi.",
                ru: "Ei, ma ei räägi.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Jah, natuke räägin.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas sa oled nõus? (Ты согласен? Да, я согласен!)",
          title: {
            et: "Kas sa oled nõus? (Ты согласен? Да, я согласен!)",
            en: "Kas sa oled nõus? (Do you agree? Yes I do!)",
            ru: "Kas sa oled nõus? (Ты согласен? Да, я согласен!)",
          },
          choices: [
            {
              value: "Võib-olla!",
              text: {
                et: "Võib-olla!",
                en: "Võib-olla!",
                ru: "Võib-olla!",
              },
            },
            {
              value: "Jah, olen!",
              text: {
                et: "Jah, olen!",
                en: "Jah, olen!",
                ru: "Jah, olen!",
              },
            },
            {
              value: "Mitte mingil juhul!",
              text: {
                et: "Mitte mingil juhul!",
                en: "Mitte mingil juhul!",
                ru: "Mitte mingil juhul!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Jah, olen!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Как вас зовут? Меня зовут Паул.",
          title: {
            et: "Как вас зовут? Меня зовут Паул.",
            en: "What is your name? My name is Paul.",
            ru: "Как вас зовут? Меня зовут Паул.",
          },
          choices: [
            {
              value: "Kui vana sa oled?  Olen kolmkümmend.",
              text: {
                et: "Kui vana sa oled?  Olen kolmkümmend.",
                en: "Kui vana sa oled?  Olen kolmkümmend.",
                ru: "Kui vana sa oled?  Olen kolmkümmend.",
              },
            },
            {
              value: "Mis on sinu nimi? Minu nimi on Paul.",
              text: {
                et: "Mis on sinu nimi? Minu nimi on Paul.",
                en: "Mis on sinu nimi? Minu nimi on Paul.",
                ru: "Mis on sinu nimi? Minu nimi on Paul.",
              },
            },
            {
              value: "Mis on teie perekonnanimi? Minu perekonnanimi on Lumi.",
              text: {
                et: "Mis on teie perekonnanimi? Minu perekonnanimi on Lumi.",
                en: "Mis on teie perekonnanimi? Minu perekonnanimi on Lumi.",
                ru: "Mis on teie perekonnanimi? Minu perekonnanimi on Lumi.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mis on sinu nimi? Minu nimi on Paul.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kelle raha see on? (Чьи это деньги? Это деньги отца.)",
          title: {
            et: "Kelle raha see on? (Чьи это деньги? Это деньги отца.)",
            en: "Kelle raha see on? (Whose  money is this? This is father’s money.)",
            ru: "Kelle raha see on? (Чьи это деньги? Это деньги отца.)",
          },
          choices: [
            {
              value: "See on vanaisa raha.",
              text: {
                et: "See on vanaisa raha.",
                en: "See on vanaisa raha.",
                ru: "See on vanaisa raha.",
              },
            },
            {
              value: "See on isa raha.",
              text: {
                et: "See on isa raha.",
                en: "See on isa raha.",
                ru: "See on isa raha.",
              },
            },
            {
              value: "See on ema raha.",
              text: {
                et: "See on ema raha.",
                en: "See on ema raha.",
                ru: "See on ema raha.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "See on isa raha.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "В какое время ты придешь? Я приду в два или три!",
          title: {
            et: "В какое время ты придешь? Я приду в два или три!",
            en: "At what time will you come? I’ll come at two or three!",
            ru: "В какое время ты придешь? Я приду в два или три!",
          },
          choices: [
            {
              value: "Mis kell on sul eksam? Ma arvan, et kell üks või kaks!",
              text: {
                et: "Mis kell on sul eksam? Ma arvan, et kell üks või kaks!",
                en: "Mis kell on sul eksam? Ma arvan, et kell üks või kaks!",
                ru: "Mis kell on sul eksam? Ma arvan, et kell üks või kaks!",
              },
            },
            {
              value: "Mis kell sa tuled? Ma tulen kell kaks või kolm.",
              text: {
                et: "Mis kell sa tuled? Ma tulen kell kaks või kolm.",
                en: "Mis kell sa tuled? Ma tulen kell kaks või kolm.",
                ru: "Mis kell sa tuled? Ma tulen kell kaks või kolm.",
              },
            },
            {
              value: "Mis kell sa lähed? Ma lähen kell kaks või kolm.",
              text: {
                et: "Mis kell sa lähed? Ma lähen kell kaks või kolm.",
                en: "Mis kell sa lähed? Ma lähen kell kaks või kolm.",
                ru: "Mis kell sa lähed? Ma lähen kell kaks või kolm.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mis kell sa tuled? Ma tulen kell kaks või kolm.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mis kell me kohtume? (В какое время мы встретимся? Давай встретимся в четверть третьего.)",
          title: {
            et: "Mis kell me kohtume? (В какое время мы встретимся? Давай встретимся в четверть третьего.)",
            en: "Mis kell me kohtume? (At what time will we meet?  Let’s meet at a quarter past two.)",
            ru: "Mis kell me kohtume? (В какое время мы встретимся? Давай встретимся в четверть третьего.)",
          },
          choices: [
            {
              value: "Kohtume veerand neli.",
              text: {
                et: "Kohtume veerand neli.",
                en: "Kohtume veerand neli.",
                ru: "Kohtume veerand neli.",
              },
            },
            {
              value: "Kohtume veerand kolm.",
              text: {
                et: "Kohtume veerand kolm.",
                en: "Kohtume veerand kolm.",
                ru: "Kohtume veerand kolm.",
              },
            },
            {
              value: "Kohtume kolmveerand kolm.",
              text: {
                et: "Kohtume kolmveerand kolm.",
                en: "Kohtume kolmveerand kolm.",
                ru: "Kohtume kolmveerand kolm.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kohtume veerand kolm.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mitu pastakat? (Сколько ручек?13 ручек.)",
          title: {
            et: "Mitu pastakat? (Сколько ручек?13 ручек.)",
            en: "Mitu pastakat? (How many pens? 13 pens.)",
            ru: "Mitu pastakat? (Сколько ручек?13 ручек.)",
          },
          choices: [
            {
              value: "Kolmkümmend pastakat.",
              text: {
                et: "Kolmkümmend pastakat.",
                en: "Kolmkümmend pastakat.",
                ru: "Kolmkümmend pastakat.",
              },
            },
            {
              value: "Kolmteist pastakat.",
              text: {
                et: "Kolmteist pastakat.",
                en: "Kolmteist pastakat.",
                ru: "Kolmteist pastakat.",
              },
            },
            {
              value: "Viisteist pastakat.",
              text: {
                et: "Viisteist pastakat.",
                en: "Viisteist pastakat.",
                ru: "Viisteist pastakat.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kolmteist pastakat.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kui vana sa oled? Ma ... (Сколько тебе лет? Мне 33 года.)",
          title: {
            et: "Kui vana sa oled? Ma ... (Сколько тебе лет? Мне 33 года.)",
            en: "Kui vana te olete? Ma ... (How old are you? I am 33 years old.)",
            ru: "Kui vana sa oled? Ma ... (Сколько тебе лет? Мне 33 года.)",
          },
          choices: [
            {
              value: "olen nelikümmend kolm aastat vana.",
              text: {
                et: "olen nelikümmend kolm aastat vana.",
                en: "olen nelikümmend kolm aastat vana.",
                ru: "olen nelikümmend kolm aastat vana.",
              },
            },
            {
              value: "olen kolmkümmend kolm aastat vana.",
              text: {
                et: "olen kolmkümmend kolm aastat vana.",
                en: "olen kolmkümmend kolm aastat vana.",
                ru: "olen kolmkümmend kolm aastat vana.",
              },
            },
            {
              value: "olen kolmekümne viie aastane.",
              text: {
                et: "olen kolmekümne viie aastane.",
                en: "olen kolmekümne viie aastane.",
                ru: "olen kolmekümne viie aastane.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "olen kolmkümmend kolm aastat vana.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Keda sa armastad? (Кого ты любишь? Я люблю его.)",
          title: {
            et: "Keda sa armastad? (Кого ты любишь? Я люблю его.)",
            en: "Keda sa armastad? (Who do you love? I love him.)",
            ru: "Keda sa armastad? (Кого ты любишь? Я люблю его.)",
          },
          choices: [
            {
              value: "Ma armastan sind!",
              text: {
                et: "Ma armastan sind!",
                en: "Ma armastan sind!",
                ru: "Ma armastan sind!",
              },
            },
            {
              value: "Ma armastan teda!",
              text: {
                et: "Ma armastan teda!",
                en: "Ma armastan teda!",
                ru: "Ma armastan teda!",
              },
            },
            {
              value: "Ma armastan ennast!",
              text: {
                et: "Ma armastan ennast!",
                en: "Ma armastan ennast!",
                ru: "Ma armastan ennast!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Ma armastan teda!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kuidas teil läheb? (Как поживаете? Спасибо! Отлично!)",
          title: {
            et: "Kuidas teil läheb? (Как поживаете? Спасибо! Отлично!)",
            en: "Kuidas sul läheb? (How are you? Thank you! Very well!)",
            ru: "Kuidas teil läheb? (Как поживаете? Спасибо! Отлично!)",
          },
          choices: [
            {
              value: "Tänan! Väga halvasti!",
              text: {
                et: "Tänan! Väga halvasti!",
                en: "Tänan! Väga halvasti!",
                ru: "Tänan! Väga halvasti!",
              },
            },
            {
              value: "Tänan! Väga hästi!",
              text: {
                et: "Tänan! Väga hästi!",
                en: "Tänan! Väga hästi!",
                ru: "Tänan! Väga hästi!",
              },
            },
            {
              value: "Tänan! Pole viga!",
              text: {
                et: "Tänan! Pole viga!",
                en: "Tänan! Pole viga!",
                ru: "Tänan! Pole viga!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Tänan! Väga hästi!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Что с тобой?",
          title: {
            et: "Что с тобой?",
            en: "What’s wrong with you?",
            ru: "Что с тобой?",
          },
          choices: [
            {
              value: "Mis sul plaanis on?",
              text: {
                et: "Mis sul plaanis on?",
                en: "Mis sul plaanis on?",
                ru: "Mis sul plaanis on?",
              },
            },
            {
              value: "Mis sul viga on?",
              text: {
                et: "Mis sul viga on?",
                en: "Mis sul viga on?",
                ru: "Mis sul viga on?",
              },
            },
            {
              value: "Mis sul kotis on?",
              text: {
                et: "Mis sul kotis on?",
                en: "Mis sul kotis on?",
                ru: "Mis sul kotis on?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mis sul viga on?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "У меня кашель и простуда.",
          title: {
            et: "У меня кашель и простуда.",
            en: "I have got a cough and a cold.",
            ru: "У меня кашель и простуда.",
          },
          choices: [
            {
              value: "Tal on köha, ta on külmetunud.",
              text: {
                et: "Tal on köha, ta on külmetunud.",
                en: "Tal on köha, ta on külmetunud.",
                ru: "Tal on köha, ta on külmetunud.",
              },
            },
            {
              value: "Mul on köha, olen külmetunud.",
              text: {
                et: "Mul on köha, olen külmetunud.",
                en: "Mul on köha, olen külmetunud.",
                ru: "Mul on köha, olen külmetunud.",
              },
            },
            {
              value: "Sul on köha, oled külmetunud.",
              text: {
                et: "Sul on köha, oled külmetunud.",
                en: "Sul on köha, oled külmetunud.",
                ru: "Sul on köha, oled külmetunud.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mul on köha, olen külmetunud.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Mis päev oli eile? (Какой вчера был день? Вчера был четверг.)",
          title: {
            et: "Mis päev oli eile? (Какой вчера был день? Вчера был четверг.)",
            en: "Mis päev oli eile? (What day was it yesterday? Yesterday was Thursday.)",
            ru: "Mis päev oli eile? (Какой вчера был день? Вчера был четверг.)",
          },
          choices: [
            {
              value: "Eile oli teisipäev.",
              text: {
                et: "Eile oli teisipäev.",
                en: "Eile oli teisipäev.",
                ru: "Eile oli teisipäev.",
              },
            },
            {
              value: "Eile oli neljapäev.",
              text: {
                et: "Eile oli neljapäev.",
                en: "Eile oli neljapäev.",
                ru: "Eile oli neljapäev.",
              },
            },
            {
              value: "Eile oli kolmapäev.",
              text: {
                et: "Eile oli kolmapäev.",
                en: "Eile oli kolmapäev.",
                ru: "Eile oli kolmapäev.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Eile oli neljapäev.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Homme on viies mai. (задайте вопрос о слове в кавычках.)",
          title: {
            et: "Homme on viies mai. (задайте вопрос о слове в кавычках.)",
            en: "Homme on viies mai. (Ask a question about the word in quotes \"viies\".)",
            ru: "Homme on viies mai. (задайте вопрос о слове в кавычках.)",
          },
          choices: [
            {
              value: "Mitmendal?",
              text: {
                et: "Mitmendal?",
                en: "Mitmendal?",
                ru: "Mitmendal?",
              },
            },
            {
              value: "Mitmendal?",
              text: {
                et: "Mitmendal?",
                en: "Mitmendal?",
                ru: "Mitmendal?",
              },
            },
            {
              value: "Mitmes?",
              text: {
                et: "Mitmes?",
                en: "Mitmes?",
                ru: "Mitmes?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mitmes?",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Millal on sinu sünnipäev? Minu sünnipäev on 07.05. (Когда у тебя день рождения?)",
          title: {
            et: "Millal on sinu sünnipäev? Minu sünnipäev on 07.05. (Когда у тебя день рождения?)",
            en: "Millal on sinu sünnipäev? Minu sünnipäev on 07.05. (When is your birthday?)",
            ru: "Millal on sinu sünnipäev? Minu sünnipäev on 07.05. (Когда у тебя день рождения?)",
          },
          choices: [
            {
              value: "seitse mais.",
              text: {
                et: "seitse mais.",
                en: "seitse mais.",
                ru: "seitse mais.",
              },
            },
            {
              value: "seitsmendal mail.",
              text: {
                et: "seitsmendal mail.",
                en: "seitsmendal mail.",
                ru: "seitsmendal mail.",
              },
            },
            {
              value: "seitsmendas mais.",
              text: {
                et: "seitsmendas mais.",
                en: "seitsmendas mais.",
                ru: "seitsmendas mais.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "seitsmendal mail.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kus sa oled? Olen ... (Где ты? На работе.)",
          title: {
            et: "Kus sa oled? Olen ... (Где ты? На работе.)",
            en: "Kus sa oled? Olen ... (Where are you?)",
            ru: "Kus sa oled? Olen ... (Где ты? На работе.)",
          },
          choices: [
            {
              value: "töös.",
              text: {
                et: "töös.",
                en: "töös.",
                ru: "töös.",
              },
            },
            {
              value: "tööl.",
              text: {
                et: "tööl.",
                en: "tööl.",
                ru: "tööl.",
              },
            },
            {
              value: "töö peal.",
              text: {
                et: "töö peal.",
                en: "töö peal.",
                ru: "töö peal.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "tööl.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kus sa oled? Olen ... (Где ты? Я у врача.)",
          title: {
            et: "Kus sa oled? Olen ... (Где ты? Я у врача.)",
            en: "Kus sa oled? Olen ... (Where are you? I’m  at the doctor’s.)",
            ru: "Kus sa oled? Olen ... (Где ты? Я у врача.)",
          },
          choices: [
            {
              value: "arsti peal.",
              text: {
                et: "arsti peal.",
                en: "arsti peal.",
                ru: "arsti peal.",
              },
            },
            {
              value: "arsti juures.",
              text: {
                et: "arsti juures.",
                en: "arsti juures.",
                ru: "arsti juures.",
              },
            },
            {
              value: "arstil.",
              text: {
                et: "arstil.",
                en: "arstil.",
                ru: "arstil.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "arsti juures.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kus sa elad? Praegu elan ... (Где ты живешь? Сейчас живу в Таллинне.)",
          title: {
            et: "Kus sa elad? Praegu elan ... (Где ты живешь? Сейчас живу в Таллинне.)",
            en: "Kus sa elad? Praegu elan ... (Where do you live? Right now I live in Tallinn.)",
            ru: "Kus sa elad? Praegu elan ... (Где ты живешь? Сейчас живу в Таллинне.)",
          },
          choices: [
            {
              value: "Tallinnal.",
              text: {
                et: "Tallinnal.",
                en: "Tallinnal.",
                ru: "Tallinnal.",
              },
            },
            {
              value: "Tallinnas.",
              text: {
                et: "Tallinnas.",
                en: "Tallinnas.",
                ru: "Tallinnas.",
              },
            },
            {
              value: "Tallinnale.",
              text: {
                et: "Tallinnale.",
                en: "Tallinnale.",
                ru: "Tallinnale.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Tallinnas.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "... sa helistad? (Кому ты звонишь?)",
          title: {
            et: "... sa helistad? (Кому ты звонишь?)",
            en: "... sa helistad? (Who are you calling?)",
            ru: "... sa helistad? (Кому ты звонишь?)",
          },
          choices: [
            {
              value: "Keda",
              text: {
                et: "Keda",
                en: "Keda",
                ru: "Keda",
              },
            },
            {
              value: "Kellele",
              text: {
                et: "Kellele",
                en: "Kellele",
                ru: "Kellele",
              },
            },
            {
              value: "Kes",
              text: {
                et: "Kes",
                en: "Kes",
                ru: "Kes",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kellele",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kas Marta kuuleb?Jah, ... (Это Марта слышит?)",
          title: {
            et: "Kas Marta kuuleb?Jah, ... (Это Марта слышит?)",
            en: "Kas Marta kuuleb? Jah, ... (Is it Marta on the phone?)",
            ru: "Kas Marta kuuleb?Jah, ... (Это Марта слышит?)",
          },
          choices: [
            {
              value: "ma tulen!",
              text: {
                et: "ma tulen!",
                en: "ma tulen!",
                ru: "ma tulen!",
              },
            },
            {
              value: "ma kuulen!",
              text: {
                et: "ma kuulen!",
                en: "ma kuulen!",
                ru: "ma kuulen!",
              },
            },
            {
              value: "ma kuuleb!",
              text: {
                et: "ma kuuleb!",
                en: "ma kuuleb!",
                ru: "ma kuuleb!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ma kuulen!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kuhu sa lähed? (Куда ты идешь? Я иду в магазин.)",
          title: {
            et: "Kuhu sa lähed? (Куда ты идешь? Я иду в магазин.)",
            en: "Kuhu sa lähed? (Where are you going? I’m going to the shop.)",
            ru: "Kuhu sa lähed? (Куда ты идешь? Я иду в магазин.)",
          },
          choices: [
            {
              value: "Ta läheb tööle.",
              text: {
                et: "Ta läheb tööle.",
                en: "Ta läheb tööle.",
                ru: "Ta läheb tööle.",
              },
            },
            {
              value: "Ma lähen poodi.",
              text: {
                et: "Ma lähen poodi.",
                en: "Ma lähen poodi.",
                ru: "Ma lähen poodi.",
              },
            },
            {
              value: "Ma lähen teatrisse.",
              text: {
                et: "Ma lähen teatrisse.",
                en: "Ma lähen teatrisse.",
                ru: "Ma lähen teatrisse.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Ma lähen poodi.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta tahab ... (Он хочет читать.)",
          title: {
            et: "Ta tahab ... (Он хочет читать.)",
            en: "Ta tahab ... (He wants to read.)",
            ru: "Ta tahab ... (Он хочет читать.)",
          },
          choices: [
            {
              value: "lugema.",
              text: {
                et: "lugema.",
                en: "lugema.",
                ru: "lugema.",
              },
            },
            {
              value: "lugeda.",
              text: {
                et: "lugeda.",
                en: "lugeda.",
                ru: "lugeda.",
              },
            },
            {
              value: "lugemas.",
              text: {
                et: "lugemas.",
                en: "lugemas.",
                ru: "lugemas.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "lugeda.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta tuleb ... (Он приходит поесть.)",
          title: {
            et: "Ta tuleb ... (Он приходит поесть.)",
            en: "Ta tuleb ... (He comes to eat.)",
            ru: "Ta tuleb ... (Он приходит поесть.)",
          },
          choices: [
            {
              value: "süüa.",
              text: {
                et: "süüa.",
                en: "süüa.",
                ru: "süüa.",
              },
            },
            {
              value: "sööma.",
              text: {
                et: "sööma.",
                en: "sööma.",
                ru: "sööma.",
              },
            },
            {
              value: "jooma.",
              text: {
                et: "jooma.",
                en: "jooma.",
                ru: "jooma.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "sööma.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Я написала письмо.",
          title: {
            et: "Я написала письмо.",
            en: "I wrote a letter.",
            ru: "Я написала письмо.",
          },
          choices: [
            {
              value: "Ma kirjutan kirja.",
              text: {
                et: "Ma kirjutan kirja.",
                en: "Ma kirjutan kirja.",
                ru: "Ma kirjutan kirja.",
              },
            },
            {
              value: "Ma kirjutasin kirja.",
              text: {
                et: "Ma kirjutasin kirja.",
                en: "Ma kirjutasin kirja.",
                ru: "Ma kirjutasin kirja.",
              },
            },
            {
              value: "Ma hakkan kirjutama kirja.",
              text: {
                et: "Ma hakkan kirjutama kirja.",
                en: "Ma hakkan kirjutama kirja.",
                ru: "Ma hakkan kirjutama kirja.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Ma kirjutasin kirja.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Они не спрашивали.",
          title: {
            et: "Они не спрашивали.",
            en: "They did not ask.",
            ru: "Они не спрашивали.",
          },
          choices: [
            {
              value: "Nad ei vastanud.",
              text: {
                et: "Nad ei vastanud.",
                en: "Nad ei vastanud.",
                ru: "Nad ei vastanud.",
              },
            },
            {
              value: "Nad ei küsinud.",
              text: {
                et: "Nad ei küsinud.",
                en: "Nad ei küsinud.",
                ru: "Nad ei küsinud.",
              },
            },
            {
              value: "Nad ei vaadanud.",
              text: {
                et: "Nad ei vaadanud.",
                en: "Nad ei vaadanud.",
                ru: "Nad ei vaadanud.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Nad ei küsinud.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Palun vabandust, ... (Извини, я ошибся!)",
          title: {
            et: "Palun vabandust, ... (Извини, я ошибся!)",
            en: "Palun vabandust, ... (I’m sorry, I made a mistake!)",
            ru: "Palun vabandust, ... (Извини, я ошибся!)",
          },
          choices: [
            {
              value: "ma jäin hiljaks!",
              text: {
                et: "ma jäin hiljaks!",
                en: "ma jäin hiljaks!",
                ru: "ma jäin hiljaks!",
              },
            },
            {
              value: "ma tegin vea!",
              text: {
                et: "ma tegin vea!",
                en: "ma tegin vea!",
                ru: "ma tegin vea!",
              },
            },
            {
              value: "ma tulen kohe!",
              text: {
                et: "ma tulen kohe!",
                en: "ma tulen kohe!",
                ru: "ma tulen kohe!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ma tegin vea!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Я не понял.",
          title: {
            et: "Я не понял.",
            en: "I did not understand.",
            ru: "Я не понял.",
          },
          choices: [
            {
              value: "Ma ei tulnud.",
              text: {
                et: "Ma ei tulnud.",
                en: "Ma ei tulnud.",
                ru: "Ma ei tulnud.",
              },
            },
            {
              value: "Ma ei saanud aru.",
              text: {
                et: "Ma ei saanud aru.",
                en: "Ma ei saanud aru.",
                ru: "Ma ei saanud aru.",
              },
            },
            {
              value: "Ma ei teinud.",
              text: {
                et: "Ma ei teinud.",
                en: "Ma ei teinud.",
                ru: "Ma ei teinud.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Ma ei saanud aru.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "... sa räägid? (С кем ты разговариваешь?)",
          title: {
            et: "... sa räägid? (С кем ты разговариваешь?)",
            en: "... sa räägid? (Who are you talking to?)",
            ru: "... sa räägid? (С кем ты разговариваешь?)",
          },
          choices: [
            {
              value: "Millega",
              text: {
                et: "Millega",
                en: "Millega",
                ru: "Millega",
              },
            },
            {
              value: "Kellega",
              text: {
                et: "Kellega",
                en: "Kellega",
                ru: "Kellega",
              },
            },
            {
              value: "Miks",
              text: {
                et: "Miks",
                en: "Miks",
                ru: "Miks",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Kellega",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kuidas sa tööl käid? (Как ты ходишь на работу?)",
          title: {
            et: "Kuidas sa tööl käid? (Как ты ходишь на работу?)",
            en: "Kuidas sa tööl käid? (How do you go to work?)",
            ru: "Kuidas sa tööl käid? (Как ты ходишь на работу?)",
          },
          choices: [
            {
              value: "Bussile.",
              text: {
                et: "Bussile.",
                en: "Bussile.",
                ru: "Bussile.",
              },
            },
            {
              value: "Bussiga.",
              text: {
                et: "Bussiga.",
                en: "Bussiga.",
                ru: "Bussiga.",
              },
            },
            {
              value: "Bussi peale.",
              text: {
                et: "Bussi peale.",
                en: "Bussi peale.",
                ru: "Bussi peale.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Bussiga.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Kuidas te maksate, sularahas või kaardiga? Ma maksan ... (Как вы будете платить - наличными или картой? Я заплачу картой.)",
          title: {
            et: "Kuidas te maksate, sularahas või kaardiga? Ma maksan ... (Как вы будете платить - наличными или картой? Я заплачу картой.)",
            en: "Kuidas te maksate, sularahas või kaardiga? Ma maksan ... (How will you pay, in cash or by card? I will pay by card.)",
            ru: "Kuidas te maksate, sularahas või kaardiga? Ma maksan ... (Как вы будете платить - наличными или картой? Я заплачу картой.)",
          },
          choices: [
            {
              value: "kaardile.",
              text: {
                et: "kaardile.",
                en: "kaardile.",
                ru: "kaardile.",
              },
            },
            {
              value: "kaardiga.",
              text: {
                et: "kaardiga.",
                en: "kaardiga.",
                ru: "kaardiga.",
              },
            },
            {
              value: "koos kaardiga.",
              text: {
                et: "koos kaardiga.",
                en: "koos kaardiga.",
                ru: "koos kaardiga.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kaardiga.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma joon kohvi ... (Пью кофе без сливок.)",
          title: {
            et: "Ma joon kohvi ... (Пью кофе без сливок.)",
            en: "Ma joon kohvi ... (I drink coffe without cream.)",
            ru: "Ma joon kohvi ... (Пью кофе без сливок.)",
          },
          choices: [
            {
              value: "koorega.",
              text: {
                et: "koorega.",
                en: "koorega.",
                ru: "koorega.",
              },
            },
            {
              value: "kooreta.",
              text: {
                et: "kooreta.",
                en: "kooreta.",
                ru: "kooreta.",
              },
            },
            {
              value: "koos koorega.",
              text: {
                et: "koos koorega.",
                en: "koos koorega.",
                ru: "koos koorega.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "kooreta.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Я бы хотел отдохнуть.",
          title: {
            et: "Я бы хотел отдохнуть.",
            en: "I would like to rest.",
            ru: "Я бы хотел отдохнуть.",
          },
          choices: [
            {
              value: "Ma tahtsin puhata.",
              text: {
                et: "Ma tahtsin puhata.",
                en: "Ma tahtsin puhata.",
                ru: "Ma tahtsin puhata.",
              },
            },
            {
              value: "Ma tahaksin puhata.",
              text: {
                et: "Ma tahaksin puhata.",
                en: "Ma tahaksin puhata.",
                ru: "Ma tahaksin puhata.",
              },
            },
            {
              value: "Ma tahaksin puhkama.",
              text: {
                et: "Ma tahaksin puhkama.",
                en: "Ma tahaksin puhkama.",
                ru: "Ma tahaksin puhkama.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Ma tahaksin puhata.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ta töötab ... (Он работает врачом.)",
          title: {
            et: "Ta töötab ... (Он работает врачом.)",
            en: "Ta töötab ... (He works as a doctor.)",
            ru: "Ta töötab ... (Он работает врачом.)",
          },
          choices: [
            {
              value: "arstiga.",
              text: {
                et: "arstiga.",
                en: "arstiga.",
                ru: "arstiga.",
              },
            },
            {
              value: "arstina.",
              text: {
                et: "arstina.",
                en: "arstina.",
                ru: "arstina.",
              },
            },
            {
              value: "arsti juures.",
              text: {
                et: "arsti juures.",
                en: "arsti juures.",
                ru: "arsti juures.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "arstina.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Palun mulle 1 ... (салат)",
          title: {
            et: "Palun mulle 1 ... (салат)",
            en: "Palun mulle 1 ... (salad)",
            ru: "Palun mulle 1 ... (салат)",
          },
          choices: [
            {
              value: "salatid!",
              text: {
                et: "salatid!",
                en: "salatid!",
                ru: "salatid!",
              },
            },
            {
              value: "salati!",
              text: {
                et: "salati!",
                en: "salati!",
                ru: "salati!",
              },
            },
            {
              value: "salat!",
              text: {
                et: "salat!",
                en: "salat!",
                ru: "salat!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "salat!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Tal ... ... ... (Он имеет Эстонское гражданство.)",
          title: {
            et: "Tal ... ... ... (Он имеет Эстонское гражданство.)",
            en: "Tal ... ... ... (He has Estonian citizenship.)",
            ru: "Tal ... ... ... (Он имеет Эстонское гражданство.)",
          },
          choices: [
            {
              value: "oli Eesti kodakondsus.",
              text: {
                et: "oli Eesti kodakondsus.",
                en: "oli Eesti kodakondsus.",
                ru: "oli Eesti kodakondsus.",
              },
            },
            {
              value: "ei ole Eesti kodakondsust.",
              text: {
                et: "ei ole Eesti kodakondsust.",
                en: "ei ole Eesti kodakondsust.",
                ru: "ei ole Eesti kodakondsust.",
              },
            },
            {
              value: "on Eesti kodakondsus.",
              text: {
                et: "on Eesti kodakondsus.",
                en: "on Eesti kodakondsus.",
                ru: "on Eesti kodakondsus.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "on Eesti kodakondsus.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma tahan ... ... (Я хочу стать учителем.)",
          title: {
            et: "Ma tahan ... ... (Я хочу стать учителем.)",
            en: "Ma tahan saada ... (I want to become a teacher.)",
            ru: "Ma tahan ... ... (Я хочу стать учителем.)",
          },
          choices: [
            {
              value: "õpetajale.",
              text: {
                et: "õpetajale.",
                en: "õpetajale.",
                ru: "õpetajale.",
              },
            },
            {
              value: "õpetajani.",
              text: {
                et: "õpetajani.",
                en: "õpetajani.",
                ru: "õpetajani.",
              },
            },
            {
              value: "õpetajaks.",
              text: {
                et: "õpetajaks.",
                en: "õpetajaks.",
                ru: "õpetajaks.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "õpetajaks",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Ma sõidan ära ... (Я уйду на неделю.)",
          title: {
            et: "Ma sõidan ära ... (Я уйду на неделю.)",
            en: "Ma sõidan ära ... (I will leave for a week.)",
            ru: "Ma sõidan ära ... (Я уйду на неделю.)",
          },
          choices: [
            {
              value: "nädalaks.",
              text: {
                et: "nädalaks.",
                en: "nädalaks.",
                ru: "nädalaks.",
              },
            },
            {
              value: "nädala.",
              text: {
                et: "nädala.",
                en: "nädala.",
                ru: "nädala.",
              },
            },
            {
              value: "nädala pärast.",
              text: {
                et: "nädala pärast.",
                en: "nädala pärast.",
                ru: "nädala pärast.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "nädalaks.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Armas Tiina! ... ... ... (Дорогая Тийна, с Днем Рождения!)",
          title: {
            et: "Armas Tiina! ... ... ... (Дорогая Тийна, с Днем Рождения!)",
            en: "Armas Tiina! ... ... ... (Dear Tiina, Happy Birthday!)",
            ru: "Armas Tiina! ... ... ... (Дорогая Тийна, с Днем Рождения!)",
          },
          choices: [
            {
              value: "Head uut aastat!",
              text: {
                et: "Head uut aastat!",
                en: "Head uut aastat!",
                ru: "Head uut aastat!",
              },
            },
            {
              value: "Palju õnne sünnipäevaks!",
              text: {
                et: "Palju õnne sünnipäevaks!",
                en: "Palju õnne sünnipäevaks!",
                ru: "Palju õnne sünnipäevaks!",
              },
            },
            {
              value: "Head jaanipäeva!",
              text: {
                et: "Head jaanipäeva!",
                en: "Head jaanipäeva!",
                ru: "Head jaanipäeva!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Palju õnne sünnipäevaks!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Передай привет Марии!",
          title: {
            et: "Передай привет Марии!",
            en: "Give my regards to Maria!",
            ru: "Передай привет Марии!",
          },
          choices: [
            {
              value: "Kingitused Mariale!",
              text: {
                et: "Kingitused Mariale!",
                en: "Kingitused Mariale!",
                ru: "Kingitused Mariale!",
              },
            },
            {
              value: "Tervitused Mariale!",
              text: {
                et: "Tervitused Mariale!",
                en: "Tervitused Mariale!",
                ru: "Tervitused Mariale!",
              },
            },
            {
              value: "Pakid Mariale!",
              text: {
                et: "Pakid Mariale!",
                en: "Pakid Mariale!",
                ru: "Pakid Mariale!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Tervitused Mariale!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "С Новым годом твою семью!",
          title: {
            et: "С Новым годом твою семью!",
            en: "Happy New Year’s Eve and Happy New Year to you and your family!",
            ru: "С Новым годом твою семью!",
          },
          choices: [
            {
              value: "Head uut aastat sinu perele!",
              text: {
                et: "Head uut aastat sinu perele!",
                en: "Head uut aastat sinu perele!",
                ru: "Head uut aastat sinu perele!",
              },
            },
            {
              value: "Head vana-aasta lõppu ja head uut aastat sulle ja su perele!",
              text: {
                et: "Head vana-aasta lõppu ja head uut aastat sulle ja su perele!",
                en: "Head vana-aasta lõppu ja head uut aastat sulle ja su perele!",
                ru: "Head vana-aasta lõppu ja head uut aastat sulle ja su perele!",
              },
            },
            {
              value: "Häid pühi sulle ja su perele!",
              text: {
                et: "Häid pühi sulle ja su perele!",
                en: "Häid pühi sulle ja su perele!",
                ru: "Häid pühi sulle ja su perele!",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Head uut aastat sinu perele!",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Me sõidame ... ... ... (Послезавтра едем на машине в Италию.)",
          title: {
            et: "Me sõidame ... ... ... (Послезавтра едем на машине в Италию.)",
            en: "Me sõidame ... ... ... (We are going by car to Italy the day after tomorrow.)",
            ru: "Me sõidame ... ... ... (Послезавтра едем на машине в Италию.)",
          },
          choices: [
            {
              value: "üleeile autoga Itaaliasse.",
              text: {
                et: "üleeile autoga Itaaliasse.",
                en: "üleeile autoga Itaaliasse.",
                ru: "üleeile autoga Itaaliasse.",
              },
            },
            {
              value: "ülehomme autoga Itaaliasse.",
              text: {
                et: "ülehomme autoga Itaaliasse.",
                en: "ülehomme autoga Itaaliasse.",
                ru: "ülehomme autoga Itaaliasse.",
              },
            },
            {
              value: "ülehomme autole Itaaliasse.",
              text: {
                et: "ülehomme autole Itaaliasse.",
                en: "ülehomme autole Itaaliasse.",
                ru: "ülehomme autole Itaaliasse.",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "ülehomme autoga Itaaliasse.",
        },
      ],
    },
    {
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "С какого времени в какое время вы работаете?",
          title: {
            et: "С какого времени в какое время вы работаете?",
            en: "From what time to what time do you work?",
            ru: "С какого времени в какое время вы работаете?",
          },
          choices: [
            {
              value: "Mis kellale mis kellani te töötate?",
              text: {
                et: "Mis kellale mis kellani te töötate?",
                en: "Mis kellale mis kellani te töötate?",
                ru: "Mis kellale mis kellani te töötate?",
              },
            },
            {
              value: "Mis kellast mis kellani te töötate?",
              text: {
                et: "Mis kellast mis kellani te töötate?",
                en: "Mis kellast mis kellani te töötate?",
                ru: "Mis kellast mis kellani te töötate?",
              },
            },
            {
              value: "Mis kellal mis kellale te töötate?",
              text: {
                et: "Mis kellal mis kellale te töötate?",
                en: "Mis kellal mis kellale te töötate?",
                ru: "Mis kellal mis kellale te töötate?",
              },
            },
            {
              value: "Ei oska vastata.",
              text: {
                et: "Ei oska vastata.",
                en: "Ei oska vastata.",
                ru: "Ei oska vastata.",
              },
            },
          ],
          correctAnswer: "Mis kellast mis kellani te töötate?",
        },
      ],
    },
  ],

  completedHtml:
    "You got {correctAnswers} answers out of {questionCount} questions",
};
