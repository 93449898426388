import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import et from './locales/et/translation.json'
import en from './locales/en/translation.json'
import ru from './locales/ru/translation.json'
// import ru from './translations/ru.json'
// import fi from './translations/fi.json'
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  et: {
    translation: et
  },
  en: {
    translation: en
  },
  ru: {
    translation: ru
  },
//   fi: {
//     translation: fi
//   }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "et",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;