// import aili from "../assets/aili.jpg";
import aili from "../assets/Aili 1_09_24.png";
import { useTranslation } from "react-i18next";
const LeftSide = () => {
  const { t } = useTranslation();
  return (
    <div className="order-last p-4 rounded-md shadow-lg lg:p-10 bg-[#E6EFF7] lg:w-96 md:flex md:items-center lg:flex-col lg:order-first h-full">
      <div className="mb-3 md:mr-8">
        <img
          className="w-32 h-32 border-4 rounded-full md:w-40 md:h-40 "
          src={aili}
          alt="Aili Tuisk"
        />
        <div>
          <h1 className="mb-4 text-xl font-semibold text-sky-800">
            Aili Tuisk
          </h1>
        </div>
        <div className="mb-6 text-sm">
          <div className="flex items-center">
            <div className="mr-2">
              📞
            </div>
            <div>+372 56 841 851</div>
          </div>
          <div className="flex items-center">
            <div className="mr-2">
              📧
            </div>
            <div>ailituisk@gmail.com</div>
          </div>
        </div>
        <div className='flex flex-col justify-start gap-1'>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/eesti-keele-kursused/">{t('EESTI KEELE KURSUSED')}</a>
          </div>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/vastuvott-oppetasu-lopetamise-tingimused/">VASTUVÕTT, ÕPPETASU, LÕPETAMISE TINGIMUSED</a>
          </div>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/oppeprotsessi-labiviimine/ ">Õppekavad</a>
          </div>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/oppekorralduse-ja-kvaliteedi-tagamise-alused/">Õppekorralduse ja kvaliteedi tagamise alused</a>
          </div>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" target="_blank" href="https://ailituisk.ee/eesti-keel/wp-content/uploads/2024/06/Andmekaitsetingimused.pdf">ANDMEKAITSETINGIMUSED</a>
          </div>
          <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/portfoolio/">Portfoolio</a>
          </div>
          {/* <div className="mb-1 uppercase text-sky-800">
            <a className="hover:underline" href="https://ailituisk.ee/eesti-keel/blogi/">Blogi</a>
          </div> */}
        </div>
      </div>
      <div className="mb-4">
        <h2 className="inline-block mb-4 font-semibold border-b border-gray-600 text-md w-18">
          {t("education")}
        </h2>
        <p className="mb-4 text-sm text-gray-600 ">{t("one")}</p>
        <p className="mb-4 text-xs text-gray-600 ">{t("two")}</p>
        <p className="mb-4 text-sm text-gray-600 ">{t("three")}</p>
      <div className="text-xs text-gray-600 ">
        <div>Aili Tuisk Koolitus OÜ</div>
        <div className="mb-4">Reg nr 16370265</div>
        <div>Tegevusluba:<br />Haridus- ja Teadusministeeriumi KK<br /> nr 1.1-3/22/60  (A2, B1)</div>
        <div>Majandustegevusteade nr 229202</div>
      </div>
      </div>
        <p className="mb-4 text-sm text-gray-600 ">Aili Tuisk Koolitus OÜ ei ole käibemaksukohustuslane</p>
    </div>
  );
};

export default LeftSide;
