import est from "../assets/est.svg";
import gb from "../assets/gb.svg";
import ru from "../assets/ru.svg";
import tk from "../assets/tk_logo.png";
import t6 from "../assets/t6.png";
import langIcon from "../assets/language-icon.svg";
import "survey-core/i18n/estonian";
import "survey-core/i18n/english";
import "survey-core/i18n/russian";
import bullet from "../assets/bullet.svg";
import { useState, useCallback, useEffect } from "react";
import { Model, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import { test1 } from "../test_1";
import { test2 } from "../test_2";
import { test3 } from "../test_3";
import axios from 'axios';
// import { demo } from "../demo";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogHeader } from "../components/TestDialog";
import arrow from "../assets/arrowthick.svg";
import ArrowIcon from "./ArrowIcon";
const Middle = () => {
  
  surveyLocalization.currentLocale = "et";
  const { t, i18n } = useTranslation();
  const [modalOopen, setModalOopen] = useState(false);
  const [testType, setTestType] = useState("");
  const [survey, setSurvey] = useState();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [correctAnsers, setCorrectAnsers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [questionsAmount, setQuestionsAmount] = useState(0);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [name, setName] = useState('');
  const [email, setemail] = useState('');
  const openTest = (type) => () => {
    setTestType(type);
    setModalOopen(true);
  };
  const closeTest = () => {
    setTestType("");
    setModalOopen(false);
  };
  const closeEmailForm = () => {
    setTestType("");
    setEmailModalOpen(false);
  };

  useEffect(() => {
    if(testType === 'test1') {
      setSurvey(new Model(test1))
    }
    if(testType === 'test2') {
      setSurvey(new Model(test2))
    }
    if(testType === 'test3') {
      setSurvey(new Model(test3))
    }
    if(testType === 'demo') {
      // setSurvey(new Model(demo))
    }
  }, [testType]);
  
  const sendMail = async () => {
    const total_points = Math.ceil((correctAnsers * 100) / questionsAmount)
    try {
      await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
        service_id: "service_668z4f9",
        template_id: "template_hpel7pa",
        user_id: "user_8Tx1Z3NKNJeCFt96fveYH",
        template_params: { to_name: name, email, points:correctAnsers, total_points }
      })
    } catch (error) {
      console.log(error)
    }
  }
  const sendMailToAili = async () => {
    const total_points = Math.ceil((correctAnsers * 100) / questionsAmount)
    try {
      await axios.post('https://api.emailjs.com/api/v1.0/email/send', {
        service_id: "service_668z4f9",
        template_id: "template_t2hk21b",
        user_id: "user_8Tx1Z3NKNJeCFt96fveYH",
        template_params: { test_nr: testType, from_name: name, email_from: email, points:correctAnsers, total_points, incorrect: '' }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const submitResults = async () => {
    try {
      setSendingEmail(true)
      await sendMail()
      await sendMailToAili()
      setName('')
      setemail('')
      setEmailModalOpen(false)
      setCorrectAnsers(0)
      setTestType('')
      setIncorrectAnswers(0)
      setSendingEmail(false)
    } catch (error) {
    console.log('error :', error);
      setSendingEmail(false)
    }
  }
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };
  // survey?.onComplete.add(surveyComplete);
  survey
            ?.onComplete
            .add(function (result) {
            setCorrectAnsers(result.getCorrectAnswerCount());
            setIncorrectAnswers(result.getInCorrectAnswerCount());
            setQuestionsAmount(result.getQuizQuestionCount());
            setShowEmailForm(true)
            setModalOopen(false)
            setEmailModalOpen(true)
            });
  
  return (
    <>
      <Dialog open={modalOopen} onOpenChange={closeTest}>
        {/* <DialogTrigger>Open</DialogTrigger> */}
        <DialogContent>
          <DialogHeader></DialogHeader>
          <div>
          {i18n.language === 'en' && <p className="text-red-500">To switch the text to English, press the “EN” button!</p>}
            {survey && <Survey model={survey} />}
            <div className="flex gap-2 items-center">
              <span>
                <img className="w-6 h-6" src={langIcon} />
              </span>
              <button
                className="hover:underline"
                onClick={() => (survey.locale = "en")}
              >
                EN
              </button>
              <button
                className="hover:underline"
                onClick={() => (survey.locale = "ru")}
              >
                RUS
              </button>
              <button
                className="hover:underline"
                onClick={() => (survey.locale = "et")}
              >
                EST
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={emailModalOpen} onOpenChange={closeEmailForm}>
        {/* <DialogTrigger>Open</DialogTrigger> */}
        <DialogContent>
          <DialogHeader></DialogHeader>
          <div>
            <div>
              <div className="text-md font-semibold mb-2">
                <p>Palun kirjutage oma nimi ja e-maili aadress. Tulemus saadetakse Teile e-mailile!</p>
                <p>Enter your name and e-mail address. The result will be sent to your e-mail! 👇</p>
              </div>
              <div className="flex flex-col gap-2">
                <input className="border shadow-md rounded-md py-1 px-2" placeholder="Nimi" onChange={(e) => setName(e.target.value)} />
                <input className="border shadow-md rounded-md py-1 px-2" placeholder="Email" onChange={(e) => setemail(e.target.value)}/>
                <button disabled={name === '' || email === ''} onClick={() => submitResults()} className="bg-blue-400 shadow-md rounded-md py-1 text-white disabled:bg-opacity-30">Saada</button>
                </div>
                </div>
            
          </div>
        </DialogContent>
      </Dialog>
      <div className="m-4 lg:m-8">
        <div className="flex justify-around mb-10">
          <div
            onClick={() => changeLang("et")}
            className={`flex items-center p-2 mr-2 shadow-sm cursor-pointer hover:bg-gray-100 rounded-md ${
              i18n.language === "et"
                ? "bg-blue-100 text-ailiDarkBlue"
                : "text-gray-600"
            }`}
          >
            <img className="" src={est} alt="Estonian" />
            <div className="hidden ml-2 text-sm md:inline-block">Eesti</div>
          </div>
          <div
            onClick={() => changeLang("en")}
            className={`flex items-center p-2 mr-2 shadow-sm cursor-pointer hover:bg-gray-100 rounded-md ${
              i18n.language === "en"
                ? "bg-blue-100 text-ailiDarkBlue"
                : "text-gray-600"
            }`}
          >
            <img className="" src={gb} alt="English" />
            <div className="hidden ml-2 text-sm md:inline-block">English</div>
          </div>
          <div
            onClick={() => changeLang("ru")}
            className={`flex items-center p-2 mr-2 shadow-sm cursor-pointer hover:bg-gray-100 rounded-md ${
              i18n.language === "ru"
                ? "bg-blue-100 text-ailiDarkBlue"
                : "text-gray-600"
            }`}
          >
            <img className="" src={ru} alt="Russian" />
            <div className="hidden ml-2 text-sm md:inline-block">Russian</div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-col mb-8">
            <div className="flex mb-2">
              <img className="w-3 mr-4" src={bullet} alt="bullet" />
              <div className="text-lg font-smeibold">{t("bulletOne")}</div>
            </div>
            <div className="flex mb-2">
              <img className="w-3 mr-4" src={bullet} alt="bullet" />
              <div className="text-lg font-smeibold">{t("bulletTwo")}</div>
            </div>
            <div className="flex mb-2">
              <img className="w-3 mr-4" src={bullet} alt="bullet" />
              <div className="text-lg font-smeibold">{t("bulletThree")}</div>
            </div>
          </div>
          <div className="">
            <div className="absolute left-0 hidden sm:block top-32 md:left-36 lg:top-36 lg:left-1/3 xl:left-94"><p dangerouslySetInnerHTML={{__html: t('take-the-test-here')}} className="text-base-content/80" />
            <ArrowIcon className='w-24' /></div>
          </div>
          <div className="flex justify-between mb-6">
            <button
              onClick={openTest("test1")}
              className="flex p-3 mr-3 text-sm font-light uppercase border rounded-md border-ailiBlue text-ailiDarkBlue focus:shadow-sm hover:bg-blue-100 focus:ring-2"
            >
              ✍️
              Test 1
            </button>
            <button
              onClick={openTest("test2")}
              className="flex p-3 mr-3 text-sm font-light uppercase border rounded-md border-ailiBlue text-ailiDarkBlue focus:shadow-sm hover:bg-blue-100 focus:ring-2"
            >
              ✍️
              Test 2
            </button>
            <button
              onClick={openTest("test3")}
              className="flex p-3 mr-3 text-sm font-light uppercase border rounded-md border-ailiBlue text-ailiDarkBlue focus:shadow-sm hover:bg-blue-100 focus:ring-2"
            >
              ✍️
              Test 3
            </button>
          </div>
          <div>
            <h2 className="inline-block mb-4 text-xl font-semibold border-b border-gray-600 w-18">
              {t("welcome")}
            </h2>
            <p
              className=""
              style={{ maxWidth: 600 }}
              dangerouslySetInnerHTML={{ __html: t("intro") }}
            ></p>
            <div className="flex justify-center gap-2">
              <img className="h-20" src={tk} />
              <img className="h-20" src={t6} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Middle;
