export const test3 = {
    title: " ",
    description:{
      et: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!',
      en: 'This language test contains 50 multiple choice questions. The test will help you find the course level you need. If you don’t know the answer, choose the last option!',
      ru: 'Языковой тест состоит из 50 вопросов с заданными вариатами отвтов. Тест поможет Вам найти соответствующий своему уровню курс. Если Вы не можете ответить на вопрос, выбирайте последний вариант!'
    },
    pages: [
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kohtume ... (Estonia teater, ees)",
            title: {
              et: "Kohtume ... (Estonia teater, ees)",
              en: "Kohtume ... (Estonia teater, ees)",
              ru: "Kohtume ... (Estonia teater, ees)",
            },
            choices: [
              {
                value: "Estonia teater ees!",
                text: {
                  et: "Estonia teater ees!",
                  en: "Estonia teater ees!",
                  ru: "Estonia teater ees!",
                },
              },
              {
                value: "Estonia teatri ees!",
                text: {
                  et: "Estonia teatri ees!",
                  en: "Estonia teatri ees!",
                  ru: "Estonia teatri ees!",
                },
              },
              {
                value: "Estonia teatrit ees!",
                text: {
                  et: "Estonia teatrit ees!",
                  en: "Estonia teatrit ees!",
                  ru: "Estonia teatrit ees!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "Estonia teatri ees!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Peatuge palun ... (see, haigla, juures)",
            title: {
              et: "Peatuge palun ... (see, haigla, juures)",
              en: "Peatuge palun ... (see, haigla, juures)",
              ru: "Peatuge palun ... (see, haigla, juures)",
            },
            choices: [
              {
                value: "see haigla juures.",
                text: {
                  et: "see haigla juures.",
                  en: "see haigla juures.",
                  ru: "see haigla juures.",
                },
              },
              {
                value: "selle haigla juures.",
                text: {
                  et: "selle haigla juures.",
                  en: "selle haigla juures.",
                  ru: "selle haigla juures.",
                },
              },
              {
                value: "seda haiglat juurde.",
                text: {
                  et: "seda haiglat juurde.",
                  en: "seda haiglat juurde.",
                  ru: "seda haiglat juurde.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "selle haigla juures.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kas ma ... (panema, uks, kinni)",
            title: {
              et: "Kas ma ... (panema, uks, kinni)",
              en: "Kas ma ... (panema, uks, kinni)",
              ru: "Kas ma ... (panema, uks, kinni)",
            },
            choices: [
              {
                value: "panema uks kinni?",
                text: {
                  et: "panema uks kinni?",
                  en: "panema uks kinni?",
                  ru: "panema uks kinni?",
                },
              },
              {
                value: "panen ukse kinni?",
                text: {
                  et: "panen ukse kinni?",
                  en: "panen ukse kinni?",
                  ru: "panen ukse kinni?",
                },
              },
              {
                value: "paneb uks kinni?",
                text: {
                  et: "paneb uks kinni?",
                  en: "paneb uks kinni?",
                  ru: "paneb uks kinni?",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "panen ukse kinni?",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Tähelepanu, laev ... (väljuma, kell, 18.40)",
            title: {
              et: "Tähelepanu, laev ... (väljuma, kell, 18.40)",
              en: "Tähelepanu, laev ... (väljuma, kell, 18.40)",
              ru: "Tähelepanu, laev ... (väljuma, kell, 18.40)",
            },
            choices: [
              {
                value: "väljuma kellast 18.40!",
                text: {
                  et: "väljuma kellast 18.40!",
                  en: "väljuma kellast 18.40!",
                  ru: "väljuma kellast 18.40!",
                },
              },
              {
                value: "väljub kell 18.40!",
                text: {
                  et: "väljub kell 18.40!",
                  en: "väljub kell 18.40!",
                  ru: "väljub kell 18.40!",
                },
              },
              {
                value: "väljus kellale 18.40!",
                text: {
                  et: "väljus kellale 18.40!",
                  en: "väljus kellale 18.40!",
                  ru: "väljus kellale 18.40!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "väljub kell 18.40!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Palun ... (sisestama, PIN-kood)",
            title: {
              et: "Palun ... (sisestama, PIN-kood)",
              en: "Palun ... (sisestama, PIN-kood)",
              ru: "Palun ... (sisestama, PIN-kood)",
            },
            choices: [
              {
                value: "sisestagu PIN-koodi!",
                text: {
                  et: "sisestagu PIN-koodi!",
                  en: "sisestagu PIN-koodi!",
                  ru: "sisestagu PIN-koodi!",
                },
              },
              {
                value: "sisestage PIN-kood!",
                text: {
                  et: "sisestage PIN-kood!",
                  en: "sisestage PIN-kood!",
                  ru: "sisestage PIN-kood!",
                },
              },
              {
                value: "sisestage PIN-koodiga!",
                text: {
                  et: "sisestage PIN-koodiga!",
                  en: "sisestage PIN-koodiga!",
                  ru: "sisestage PIN-koodiga!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "sisestage PIN-kood!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Lund ... ja teed on ... (sadama, libe)",
            title: {
              et: "Lund ... ja teed on ... (sadama, libe)",
              en: "Lund ... ja teed on ... (sadama, libe)",
              ru: "Lund ... ja teed on ... (sadama, libe)",
            },
            choices: [
              {
                value: "sadama ja teed on libe.",
                text: {
                  et: "sadama ja teed on libe.",
                  en: "sadama ja teed on libe.",
                  ru: "sadama ja teed on libe.",
                },
              },
              {
                value: "sajab ja teed on libedad.",
                text: {
                  et: "sajab ja teed on libedad.",
                  en: "sajab ja teed on libedad.",
                  ru: "sajab ja teed on libedad.",
                },
              },
              {
                value: "sajab ja teed on libed.",
                text: {
                  et: "sajab ja teed on libed.",
                  en: "sajab ja teed on libed.",
                  ru: "sajab ja teed on libed.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "sajab ja teed on libedad.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma sooviksin ... (kviitung)",
            title: {
              et: "Ma sooviksin ... (kviitung)",
              en: "Ma sooviksin ... (kviitung)",
              ru: "Ma sooviksin ... (kviitung)",
            },
            choices: [
              {
                value: "kviitungile.",
                text: {
                  et: "kviitungile.",
                  en: "kviitungile.",
                  ru: "kviitungile.",
                },
              },
              {
                value: "kviitungit.",
                text: {
                  et: "kviitungit.",
                  en: "kviitungit.",
                  ru: "kviitungit.",
                },
              },
              {
                value: "kviitung.",
                text: {
                  et: "kviitung.",
                  en: "kviitung.",
                  ru: "kviitung.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kviitungit.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Tal on väga ... kolleegid. (tore)",
            title: {
              et: "Tal on väga ... kolleegid. (tore)",
              en: "Tal on väga ... kolleegid. (tore)",
              ru: "Tal on väga ... kolleegid. (tore)",
            },
            choices: [
              {
                value: "tored",
                text: {
                  et: "tored",
                  en: "tored",
                  ru: "tored",
                },
              },
              {
                value: "toredad",
                text: {
                  et: "toredad",
                  en: "toredad",
                  ru: "toredad",
                },
              },
              {
                value: "tore",
                text: {
                  et: "tore",
                  en: "tore",
                  ru: "tore",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "toredad",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Aitäh, ma sain sinu paki ...",
            title: {
              et: "Aitäh, ma sain sinu paki ...",
              en: "Aitäh, ma sain sinu paki ...",
              ru: "Aitäh, ma sain sinu paki ...",
            },
            choices: [
              {
                value: "juures!",
                text: {
                  et: "juures!",
                  en: "juures!",
                  ru: "juures!",
                },
              },
              {
                value: "kätte!",
                text: {
                  et: "kätte!",
                  en: "kätte!",
                  ru: "kätte!",
                },
              },
              {
                value: "katki!",
                text: {
                  et: "katki!",
                  en: "katki!",
                  ru: "katki!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kätte!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kas saate tulla ... kolme?",
            title: {
              et: "Kas saate tulla ... kolme?",
              en: "Kas saate tulla ... kolme?",
              ru: "Kas saate tulla ... kolme?",
            },
            choices: [
              {
                value: "hiljem",
                text: {
                  et: "hiljem",
                  en: "hiljem",
                  ru: "hiljem",
                },
              },
              {
                value: "peale",
                text: {
                  et: "peale",
                  en: "peale",
                  ru: "peale",
                },
              },
              {
                value: "üle",
                text: {
                  et: "üle",
                  en: "üle",
                  ru: "üle",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "peale",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Tal on jalas ... (saabas, püksid)",
            title: {
              et: "Tal on jalas ... (saabas, püksid)",
              en: "Tal on jalas ... (saabas, püksid)",
              ru: "Tal on jalas ... (saabas, püksid)",
            },
            choices: [
              {
                value: "saabas ja püksid.",
                text: {
                  et: "saabas ja püksid.",
                  en: "saabas ja püksid.",
                  ru: "saabas ja püksid.",
                },
              },
              {
                value: "saapad ja püksid.",
                text: {
                  et: "saapad ja püksid.",
                  en: "saapad ja püksid.",
                  ru: "saapad ja püksid.",
                },
              },
              {
                value: "saapaid ja pükse.",
                text: {
                  et: "saapaid ja pükse.",
                  en: "saapaid ja pükse.",
                  ru: "saapaid ja pükse.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "saapad ja püksid.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma palun ... (500, gramm, kanasink)",
            title: {
              et: "Ma palun ... (500, gramm, kanasink)",
              en: "Ma palun ... (500, gramm, kanasink)",
              ru: "Ma palun ... (500, gramm, kanasink)",
            },
            choices: [
              {
                value: "viissada grammid kanasingid!",
                text: {
                  et: "viissada grammid kanasingid!",
                  en: "viissada grammid kanasingid!",
                  ru: "viissada grammid kanasingid!",
                },
              },
              {
                value: "viissada grammi kanasinki!",
                text: {
                  et: "viissada grammi kanasinki!",
                  en: "viissada grammi kanasinki!",
                  ru: "viissada grammi kanasinki!",
                },
              },
              {
                value: "viissada grammile kanasingile!",
                text: {
                  et: "viissada grammile kanasingile!",
                  en: "viissada grammile kanasingile!",
                  ru: "viissada grammile kanasingile!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "viissada grammi kanasinki!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Talvekaubad on ... (alla hindama)",
            title: {
              et: "Talvekaubad on ... (alla hindama)",
              en: "Talvekaubad on ... (alla hindama)",
              ru: "Talvekaubad on ... (alla hindama)",
            },
            choices: [
              {
                value: "alla hindama.",
                text: {
                  et: "alla hindama.",
                  en: "alla hindama.",
                  ru: "alla hindama.",
                },
              },
              {
                value: "alla hinnatud.",
                text: {
                  et: "alla hinnatud.",
                  en: "alla hinnatud.",
                  ru: "alla hinnatud.",
                },
              },
              {
                value: "alla hinnata.",
                text: {
                  et: "alla hinnata.",
                  en: "alla hinnata.",
                  ru: "alla hinnata.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "alla hinnatud.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Salong on avatud ... (9 -17.00 / üheksa, seitseteist)",
            title: {
              et: "Salong on avatud ... (9 -17.00 / üheksa, seitseteist)",
              en: "Salong on avatud ... (9 -17.00 / üheksa, seitseteist)",
              ru: "Salong on avatud ... (9 -17.00 / üheksa, seitseteist)",
            },
            choices: [
              {
                value: "üheksale seitsmeteistkümnele.",
                text: {
                  et: "üheksale seitsmeteistkümnele.",
                  en: "üheksale seitsmeteistkümnele.",
                  ru: "üheksale seitsmeteistkümnele.",
                },
              },
              {
                value: "üheksast seitsmeteistkümneni.",
                text: {
                  et: "üheksast seitsmeteistkümneni.",
                  en: "üheksast seitsmeteistkümneni.",
                  ru: "üheksast seitsmeteistkümneni.",
                },
              },
              {
                value: "üheksani seitsmeteistkümneni.",
                text: {
                  et: "üheksani seitsmeteistkümneni.",
                  en: "üheksani seitsmeteistkümneni.",
                  ru: "üheksani seitsmeteistkümneni.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "üheksast seitsmeteistkümneni.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma ... koti bussi. (unustama)",
            title: {
              et: "Ma ... koti bussi. (unustama)",
              en: "Ma ... koti bussi. (unustama)",
              ru: "Ma ... koti bussi. (unustama)",
            },
            choices: [
              {
                value: "unustanud",
                text: {
                  et: "unustanud",
                  en: "unustanud",
                  ru: "unustanud",
                },
              },
              {
                value: "unustasin",
                text: {
                  et: "unustasin",
                  en: "unustasin",
                  ru: "unustasin",
                },
              },
              {
                value: "unustasid",
                text: {
                  et: "unustasid",
                  en: "unustasid",
                  ru: "unustasid",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "unustasin",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mitu tabletti ... suures karbis? (olema)",
            title: {
              et: "Mitu tabletti ... suures karbis? (olema)",
              en: "Mitu tabletti ... suures karbis? (olema)",
              ru: "Mitu tabletti ... suures karbis? (olema)",
            },
            choices: [
              {
                value: "on",
                text: {
                  et: "on",
                  en: "on",
                  ru: "on",
                },
              },
              {
                value: "olete",
                text: {
                  et: "olete",
                  en: "olete",
                  ru: "olete",
                },
              },
              {
                value: "oleme",
                text: {
                  et: "oleme",
                  en: "oleme",
                  ru: "oleme",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "on",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "See hammas tuleb ... (välja tõmbama)",
            title: {
              et: "See hammas tuleb ... (välja tõmbama)",
              en: "See hammas tuleb ... (välja tõmbama)",
              ru: "See hammas tuleb ... (välja tõmbama)",
            },
            choices: [
              {
                value: "välja tõmbama!",
                text: {
                  et: "välja tõmbama!",
                  en: "välja tõmbama!",
                  ru: "välja tõmbama!",
                },
              },
              {
                value: "välja tõmban!",
                text: {
                  et: "välja tõmban!",
                  en: "välja tõmban!",
                  ru: "välja tõmban!",
                },
              },
              {
                value: "välja tõmmata!",
                text: {
                  et: "välja tõmmata!",
                  en: "välja tõmmata!",
                  ru: "välja tõmmata!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "välja tõmmata!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mul on vaja ... (plaaster)",
            title: {
              et: "Mul on vaja ... (plaaster)",
              en: "Mul on vaja ... (plaaster)",
              ru: "Mul on vaja ... (plaaster)",
            },
            choices: [
              {
                value: "plaaster.",
                text: {
                  et: "plaaster.",
                  en: "plaaster.",
                  ru: "plaaster.",
                },
              },
              {
                value: "plaastrile.",
                text: {
                  et: "plaastrile.",
                  en: "plaastrile.",
                  ru: "plaastrile.",
                },
              },
              {
                value: "plaastrit.",
                text: {
                  et: "plaastrit.",
                  en: "plaastrit.",
                  ru: "plaastrit.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "plaastrit.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma ei saa aru, kuidas see aparaat ... (töötama)",
            title: {
              et: "Ma ei saa aru, kuidas see aparaat ... (töötama)",
              en: "Ma ei saa aru, kuidas see aparaat ... (töötama)",
              ru: "Ma ei saa aru, kuidas see aparaat ... (töötama)",
            },
            choices: [
              {
                value: "töötab.",
                text: {
                  et: "töötab.",
                  en: "töötab.",
                  ru: "töötab.",
                },
              },
              {
                value: "töötavad.",
                text: {
                  et: "töötavad.",
                  en: "töötavad.",
                  ru: "töötavad.",
                },
              },
              {
                value: "töötame.",
                text: {
                  et: "töötame.",
                  en: "töötame.",
                  ru: "töötame.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "töötab.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Palun ... turvavöö! (kinnitama)",
            title: {
              et: "Palun ... turvavöö! (kinnitama)",
              en: "Palun ... turvavöö! (kinnitama)",
              ru: "Palun ... turvavöö! (kinnitama)",
            },
            choices: [
              {
                value: "kinnitage",
                text: {
                  et: "kinnitage",
                  en: "kinnitage",
                  ru: "kinnitage",
                },
              },
              {
                value: "kinnitas",
                text: {
                  et: "kinnitas",
                  en: "kinnitas",
                  ru: "kinnitas",
                },
              },
              {
                value: "kinni",
                text: {
                  et: "kinni",
                  en: "kinni",
                  ru: "kinni",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kinnitage",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kohale ... politsei. (kutsuma)",
            title: {
              et: "Kohale ... politsei. (kutsuma)",
              en: "Kohale ... politsei. (kutsuma)",
              ru: "Kohale ... politsei. (kutsuma)",
            },
            choices: [
              {
                value: "kutsub",
                text: {
                  et: "kutsub",
                  en: "kutsub",
                  ru: "kutsub",
                },
              },
              {
                value: "kutsuti",
                text: {
                  et: "kutsuti",
                  en: "kutsuti",
                  ru: "kutsuti",
                },
              },
              {
                value: "kutsuvad",
                text: {
                  et: "kutsuvad",
                  en: "kutsuvad",
                  ru: "kutsuvad",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kutsuti",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma tegin uue ... (pangakaart)",
            title: {
              et: "Ma tegin uue ... (pangakaart)",
              en: "Ma tegin uue ... (pangakaart)",
              ru: "Ma tegin uue ... (pangakaart)",
            },
            choices: [
              {
                value: "pangakaarti.",
                text: {
                  et: "pangakaarti.",
                  en: "pangakaarti.",
                  ru: "pangakaarti.",
                },
              },
              {
                value: "pangakaardi.",
                text: {
                  et: "pangakaardi.",
                  en: "pangakaardi.",
                  ru: "pangakaardi.",
                },
              },
              {
                value: "pangakaardiga.",
                text: {
                  et: "pangakaardiga.",
                  en: "pangakaardiga.",
                  ru: "pangakaardiga.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "pangakaardi.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Meile ei ... veekeskuse saunad. (meeldima)",
            title: {
              et: "Meile ei ... veekeskuse saunad. (meeldima)",
              en: "Meile ei ... veekeskuse saunad. (meeldima)",
              ru: "Meile ei ... veekeskuse saunad. (meeldima)",
            },
            choices: [
              {
                value: "meeldis",
                text: {
                  et: "meeldis",
                  en: "meeldis",
                  ru: "meeldis",
                },
              },
              {
                value: "meeldinud",
                text: {
                  et: "meeldinud",
                  en: "meeldinud",
                  ru: "meeldinud",
                },
              },
              {
                value: "meeldivad",
                text: {
                  et: "meeldivad",
                  en: "meeldivad",
                  ru: "meeldivad",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "meeldinud",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Minu laps jäi haigeks, pean täna varem ... (töö, lahkuma)",
            title: {
              et: "Minu laps jäi haigeks, pean täna varem ... (töö, lahkuma)",
              en: "Minu laps jäi haigeks, pean täna varem ... (töö, lahkuma)",
              ru: "Minu laps jäi haigeks, pean täna varem ... (töö, lahkuma)",
            },
            choices: [
              {
                value: "töölt lahkuma!",
                text: {
                  et: "töölt lahkuma!",
                  en: "töölt lahkuma!",
                  ru: "töölt lahkuma!",
                },
              },
              {
                value: "tööle lahkuda!",
                text: {
                  et: "tööle lahkuda!",
                  en: "tööle lahkuda!",
                  ru: "tööle lahkuda!",
                },
              },
              {
                value: "töölt lahkuda!",
                text: {
                  et: "töölt lahkuda!",
                  en: "töölt lahkuda!",
                  ru: "töölt lahkuda!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "töölt lahkuma!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Minu abikaasa on ... (hea iseloom)",
            title: {
              et: "Minu abikaasa on ... (hea iseloom)",
              en: "Minu abikaasa on ... (hea iseloom)",
              ru: "Minu abikaasa on ... (hea iseloom)",
            },
            choices: [
              {
                value: "heaga iseloomuga.",
                text: {
                  et: "heaga iseloomuga.",
                  en: "heaga iseloomuga.",
                  ru: "heaga iseloomuga.",
                },
              },
              {
                value: "hea iseloomuga.",
                text: {
                  et: "hea iseloomuga.",
                  en: "hea iseloomuga.",
                  ru: "hea iseloomuga.",
                },
              },
              {
                value: "head iseloomud.",
                text: {
                  et: "head iseloomud.",
                  en: "head iseloomud.",
                  ru: "head iseloomud.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "hea iseloomuga.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "... koos sa trennis käid?",
            title: {
              et: "... koos sa trennis käid?",
              en: "... koos sa trennis käid?",
              ru: "... koos sa trennis käid?",
            },
            choices: [
              {
                value: "Millega",
                text: {
                  et: "Millega",
                  en: "Millega",
                  ru: "Millega",
                },
              },
              {
                value: "Kellega",
                text: {
                  et: "Kellega",
                  en: "Kellega",
                  ru: "Kellega",
                },
              },
              {
                value: "Kellele",
                text: {
                  et: "Kellele",
                  en: "Kellele",
                  ru: "Kellele",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "Kellega",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Tahan ... puhkusereisi Tenerifele? (broneerima)",
            title: {
              et: "Tahan ... puhkusereisi Tenerifele? (broneerima)",
              en: "Tahan ... puhkusereisi Tenerifele? (broneerima)",
              ru: "Tahan ... puhkusereisi Tenerifele? (broneerima)",
            },
            choices: [
              {
                value: "broneerinud",
                text: {
                  et: "broneerinud",
                  en: "broneerinud",
                  ru: "broneerinud",
                },
              },
              {
                value: "broneerida",
                text: {
                  et: "broneerida",
                  en: "broneerida",
                  ru: "broneerida",
                },
              },
              {
                value: "broneerima",
                text: {
                  et: "broneerima",
                  en: "broneerima",
                  ru: "broneerima",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "broneerida",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mis ... on Maria abikaasa? (rahvus)",
            title: {
              et: "Mis ... on Maria abikaasa? (rahvus)",
              en: "Mis ... on Maria abikaasa? (rahvus)",
              ru: "Mis ... on Maria abikaasa? (rahvus)",
            },
            choices: [
              {
                value: "rahvusega",
                text: {
                  et: "rahvusega",
                  en: "rahvusega",
                  ru: "rahvusega",
                },
              },
              {
                value: "rahvusest",
                text: {
                  et: "rahvusest",
                  en: "rahvusest",
                  ru: "rahvusest",
                },
              },
              {
                value: "rahvust",
                text: {
                  et: "rahvust",
                  en: "rahvust",
                  ru: "rahvust",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "rahvusest",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kas üldiselt ... reisida auto, bussi või lennukiga? (eelistama)",
            title: {
              et: "Kas üldiselt ... reisida auto, bussi või lennukiga? (eelistama)",
              en: "Kas üldiselt ... reisida auto, bussi või lennukiga? (eelistama)",
              ru: "Kas üldiselt ... reisida auto, bussi või lennukiga? (eelistama)",
            },
            choices: [
              {
                value: "eelistan",
                text: {
                  et: "eelistan",
                  en: "eelistan",
                  ru: "eelistan",
                },
              },
              {
                value: "eelistate",
                text: {
                  et: "eelistate",
                  en: "eelistate",
                  ru: "eelistate",
                },
              },
              {
                value: "eelistasin",
                text: {
                  et: "eelistasin",
                  en: "eelistasin",
                  ru: "eelistasin",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "eelistate",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kõige tervislikum on trenni ajal ... tavalist vett! (jooma)",
            title: {
              et: "Kõige tervislikum on trenni ajal ... tavalist vett! (jooma)",
              en: "Kõige tervislikum on trenni ajal ... tavalist vett! (jooma)",
              ru: "Kõige tervislikum on trenni ajal ... tavalist vett! (jooma)",
            },
            choices: [
              {
                value: "juua",
                text: {
                  et: "juua",
                  en: "juua",
                  ru: "juua",
                },
              },
              {
                value: "jooma",
                text: {
                  et: "jooma",
                  en: "jooma",
                  ru: "jooma",
                },
              },
              {
                value: "joovad",
                text: {
                  et: "joovad",
                  en: "joovad",
                  ru: "joovad",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "juua",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Meie perele ... rohkem lumelauaga sõita kui rannas puhata. (meeldima)",
            title: {
              et: "Meie perele ... rohkem lumelauaga sõita kui rannas puhata. (meeldima)",
              en: "Meie perele ... rohkem lumelauaga sõita kui rannas puhata. (meeldima)",
              ru: "Meie perele ... rohkem lumelauaga sõita kui rannas puhata. (meeldima)",
            },
            choices: [
              {
                value: "meeldivad",
                text: {
                  et: "meeldivad",
                  en: "meeldivad",
                  ru: "meeldivad",
                },
              },
              {
                value: "meeldib",
                text: {
                  et: "meeldib",
                  en: "meeldib",
                  ru: "meeldib",
                },
              },
              {
                value: "meeldime",
                text: {
                  et: "meeldime",
                  en: "meeldime",
                  ru: "meeldime",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "meeldib.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Milliste hobidega ... teie lapsed? (tegelema)",
            title: {
              et: "Milliste hobidega ... teie lapsed? (tegelema)",
              en: "Milliste hobidega ... teie lapsed? (tegelema)",
              ru: "Milliste hobidega ... teie lapsed? (tegelema)",
            },
            choices: [
              {
                value: "tegeleda",
                text: {
                  et: "tegeleda",
                  en: "tegeleda",
                  ru: "tegeleda",
                },
              },
              {
                value: "tegelevad",
                text: {
                  et: "tegelevad",
                  en: "tegelevad",
                  ru: "tegelevad",
                },
              },
              {
                value: "tegeleb",
                text: {
                  et: "tegeleb",
                  en: "tegeleb",
                  ru: "tegeleb",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "tegelevad",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Draamateatri hooaeg kestab ... (sügis, kevad)",
            title: {
              et: "Draamateatri hooaeg kestab ... (sügis, kevad)",
              en: "Draamateatri hooaeg kestab ... (sügis, kevad)",
              ru: "Draamateatri hooaeg kestab ... (sügis, kevad)",
            },
            choices: [
              {
                value: "sügisele kevadele.",
                text: {
                  et: "sügisele kevadele.",
                  en: "sügisele kevadele.",
                  ru: "sügisele kevadele.",
                },
              },
              {
                value: "sügisest kevadeni.",
                text: {
                  et: "sügisest kevadeni.",
                  en: "sügisest kevadeni.",
                  ru: "sügisest kevadeni.",
                },
              },
              {
                value: "sügiseni kevadeni.",
                text: {
                  et: "sügiseni kevadeni.",
                  en: "sügiseni kevadeni.",
                  ru: "sügiseni kevadeni.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "sügisest kevadeni.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Nad vahetasid sooja kliima ... (külm kliima, vastu)",
            title: {
              et: "Nad vahetasid sooja kliima ... (külm kliima, vastu)",
              en: "Nad vahetasid sooja kliima ... (külm kliima, vastu)",
              ru: "Nad vahetasid sooja kliima ... (külm kliima, vastu)",
            },
            choices: [
              {
                value: "külm kliima vastu.",
                text: {
                  et: "külm kliima vastu.",
                  en: "külm kliima vastu.",
                  ru: "külm kliima vastu.",
                },
              },
              {
                value: "külma kliima vastu.",
                text: {
                  et: "külma kliima vastu.",
                  en: "külma kliima vastu.",
                  ru: "külma kliima vastu.",
                },
              },
              {
                value: "vastu külmale kliimale.",
                text: {
                  et: "vastu külmale kliimale.",
                  en: "vastu külmale kliimale.",
                  ru: "vastu külmale kliimale.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "külma kliima vastu.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mark ja Sebastian otsustasid ... eesti keelt. (õppima)",
            title: {
              et: "Mark ja Sebastian otsustasid ... eesti keelt. (õppima)",
              en: "Mark ja Sebastian otsustasid ... eesti keelt. (õppima)",
              ru: "Mark ja Sebastian otsustasid ... eesti keelt. (õppima)",
            },
            choices: [
              {
                value: "õppima",
                text: {
                  et: "õppima",
                  en: "õppima",
                  ru: "õppima",
                },
              },
              {
                value: "õppida",
                text: {
                  et: "õppida",
                  en: "õppida",
                  ru: "õppida",
                },
              },
              {
                value: "õpivad",
                text: {
                  et: "õpivad",
                  en: "õpivad",
                  ru: "õpivad",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "õppida",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Kui kaua meie tund ... (kestma)",
            title: {
              et: "Kui kaua meie tund ... (kestma)",
              en: "Kui kaua meie tund ... (kestma)",
              ru: "Kui kaua meie tund ... (kestma)",
            },
            choices: [
              {
                value: "kestma?",
                text: {
                  et: "kestma?",
                  en: "kestma?",
                  ru: "kestma?",
                },
              },
              {
                value: "kestab?",
                text: {
                  et: "kestab?",
                  en: "kestab?",
                  ru: "kestab?",
                },
              },
              {
                value: "kestavad?",
                text: {
                  et: "kestavad?",
                  en: "kestavad?",
                  ru: "kestavad?",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kestab?",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ta ... täna ennast natuke paremini! (tundma)",
            title: {
              et: "Ta ... täna ennast natuke paremini! (tundma)",
              en: "Ta ... täna ennast natuke paremini! (tundma)",
              ru: "Ta ... täna ennast natuke paremini! (tundma)",
            },
            choices: [
              {
                value: "tundma",
                text: {
                  et: "tundma",
                  en: "tundma",
                  ru: "tundma",
                },
              },
              {
                value: "tunneb",
                text: {
                  et: "tunneb",
                  en: "tunneb",
                  ru: "tunneb",
                },
              },
              {
                value: "tunda",
                text: {
                  et: "tunda",
                  en: "tunda",
                  ru: "tunda",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "tunneb",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Uku Suvistel ja Koit Toomel on ... pikk karjäär. (selg, taga)",
            title: {
              et: "Uku Suvistel ja Koit Toomel on ... pikk karjäär. (selg, taga)",
              en: "Uku Suvistel ja Koit Toomel on ... pikk karjäär. (selg, taga)",
              ru: "Uku Suvistel ja Koit Toomel on ... pikk karjäär. (selg, taga)",
            },
            choices: [
              {
                value: "seljas taga",
                text: {
                  et: "seljas taga",
                  en: "seljas taga",
                  ru: "seljas taga",
                },
              },
              {
                value: "selja taga",
                text: {
                  et: "selja taga",
                  en: "selja taga",
                  ru: "selja taga",
                },
              },
              {
                value: "seljale taga",
                text: {
                  et: "seljale taga",
                  en: "seljale taga",
                  ru: "seljale taga",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "selja taga",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Vesi on põrandal. Katus ... (läbi laskma)",
            title: {
              et: "Vesi on põrandal. Katus ... (läbi laskma)",
              en: "Vesi on põrandal. Katus ... (läbi laskma)",
              ru: "Vesi on põrandal. Katus ... (läbi laskma)",
            },
            choices: [
              {
                value: "laskma läbi.",
                text: {
                  et: "laskma läbi.",
                  en: "laskma läbi.",
                  ru: "laskma läbi.",
                },
              },
              {
                value: "laseb läbi.",
                text: {
                  et: "laseb läbi.",
                  en: "laseb läbi.",
                  ru: "laseb läbi.",
                },
              },
              {
                value: "lasevad läbi.",
                text: {
                  et: "lasevad läbi.",
                  en: "lasevad läbi.",
                  ru: "lasevad läbi.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "laseb läbi.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mis kell see rong Tartusse ... (jõudma)",
            title: {
              et: "Mis kell see rong Tartusse ... (jõudma)",
              en: "Mis kell see rong Tartusse ... (jõudma)",
              ru: "Mis kell see rong Tartusse ... (jõudma)",
            },
            choices: [
              {
                value: "jõuda?",
                text: {
                  et: "jõuda?",
                  en: "jõuda?",
                  ru: "jõuda?",
                },
              },
              {
                value: "jõuab?",
                text: {
                  et: "jõuab?",
                  en: "jõuab?",
                  ru: "jõuab?",
                },
              },
              {
                value: "jõuavad?",
                text: {
                  et: "jõuavad?",
                  en: "jõuavad?",
                  ru: "jõuavad?",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "jõuab?",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Helista mulle ... (10, minut, pärast)",
            title: {
              et: "Helista mulle ... (10, minut, pärast)",
              en: "Helista mulle ... (10, minut, pärast)",
              ru: "Helista mulle ... (10, minut, pärast)",
            },
            choices: [
              {
                value: "kümme minutit pärast!",
                text: {
                  et: "kümme minutit pärast!",
                  en: "kümme minutit pärast!",
                  ru: "kümme minutit pärast!",
                },
              },
              {
                value: "kümne minuti pärast!",
                text: {
                  et: "kümne minuti pärast!",
                  en: "kümne minuti pärast!",
                  ru: "kümne minuti pärast!",
                },
              },
              {
                value: "pärast kümme minutit!",
                text: {
                  et: "pärast kümme minutit!",
                  en: "pärast kümme minutit!",
                  ru: "pärast kümme minutit!",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "kümne minuti pärast!",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ületasin kiirust. Politsei ... (tegema, trahv)",
            title: {
              et: "Ületasin kiirust. Politsei ... (tegema, trahv)",
              en: "Ületasin kiirust. Politsei ... (tegema, trahv)",
              ru: "Ületasin kiirust. Politsei ... (tegema, trahv)",
            },
            choices: [
              {
                value: "teha trahvi.",
                text: {
                  et: "teha trahvi.",
                  en: "teha trahvi.",
                  ru: "teha trahvi.",
                },
              },
              {
                value: "tegi trahvi.",
                text: {
                  et: "tegi trahvi.",
                  en: "tegi trahvi.",
                  ru: "tegi trahvi.",
                },
              },
              {
                value: "tegid trahvi.",
                text: {
                  et: "tegid trahvi.",
                  en: "tegid trahvi.",
                  ru: "tegid trahvi.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "tegi trahvi.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Autoteel ei ... mängida ega joosta! (tohtima)",
            title: {
              et: "Autoteel ei ... mängida ega joosta! (tohtima)",
              en: "Autoteel ei ... mängida ega joosta! (tohtima)",
              ru: "Autoteel ei ... mängida ega joosta! (tohtima)",
            },
            choices: [
              {
                value: "tohtida",
                text: {
                  et: "tohtida",
                  en: "tohtida",
                  ru: "tohtida",
                },
              },
              {
                value: "tohi",
                text: {
                  et: "tohi",
                  en: "tohi",
                  ru: "tohi",
                },
              },
              {
                value: "tohtima",
                text: {
                  et: "tohtima",
                  en: "tohtima",
                  ru: "tohtima",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "tohi",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Miks sa eile tööl ei ...(olema)",
            title: {
              et: "Miks sa eile tööl ei ...(olema)",
              en: "Miks sa eile tööl ei ...(olema)",
              ru: "Miks sa eile tööl ei ...(olema)",
            },
            choices: [
              {
                value: "olema?",
                text: {
                  et: "olema?",
                  en: "olema?",
                  ru: "olema?",
                },
              },
              {
                value: "olnud?",
                text: {
                  et: "olnud?",
                  en: "olnud?",
                  ru: "olnud?",
                },
              },
              {
                value: "olla?",
                text: {
                  et: "olla?",
                  en: "olla?",
                  ru: "olla?",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "olnud?",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma töötasin firmas 6 aastat, aga nüüd mind ... (koondama)",
            title: {
              et: "Ma töötasin firmas 6 aastat, aga nüüd mind ... (koondama)",
              en: "Ma töötasin firmas 6 aastat, aga nüüd mind ... (koondama)",
              ru: "Ma töötasin firmas 6 aastat, aga nüüd mind ... (koondama)",
            },
            choices: [
              {
                value: "koondatud.",
                text: {
                  et: "koondatud.",
                  en: "koondatud.",
                  ru: "koondatud.",
                },
              },
              {
                value: "koondati.",
                text: {
                  et: "koondati.",
                  en: "koondati.",
                  ru: "koondati.",
                },
              },
              {
                value: "koondada.",
                text: {
                  et: "koondada.",
                  en: "koondada.",
                  ru: "koondada.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "koondati.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mul on tähtajaline tööleping. See ... järgmise aasta lõpus. (lõppema)",
            title: {
              et: "Mul on tähtajaline tööleping. See ... järgmise aasta lõpus. (lõppema)",
              en: "Mul on tähtajaline tööleping. See ... järgmise aasta lõpus. (lõppema)",
              ru: "Mul on tähtajaline tööleping. See ... järgmise aasta lõpus. (lõppema)",
            },
            choices: [
              {
                value: "lõppema",
                text: {
                  et: "lõppema",
                  en: "lõppema",
                  ru: "lõppema",
                },
              },
              {
                value: "lõpeb",
                text: {
                  et: "lõpeb",
                  en: "lõpeb",
                  ru: "lõpeb",
                },
              },
              {
                value: "lõppes",
                text: {
                  et: "lõppes",
                  en: "lõppes",
                  ru: "lõppes",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "lõpeb",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ülemus ... töötajatele preemiat maksta. (lubama)",
            title: {
              et: "Ülemus ... töötajatele preemiat maksta. (lubama)",
              en: "Ülemus ... töötajatele preemiat maksta. (lubama)",
              ru: "Ülemus ... töötajatele preemiat maksta. (lubama)",
            },
            choices: [
              {
                value: "lubada",
                text: {
                  et: "lubada",
                  en: "lubada",
                  ru: "lubada",
                },
              },
              {
                value: "lubas",
                text: {
                  et: "lubas",
                  en: "lubas",
                  ru: "lubas",
                },
              },
              {
                value: "lubasin",
                text: {
                  et: "lubasin",
                  en: "lubasin",
                  ru: "lubasin",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "lubas",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma olen sündinud 05.06.1971.",
            title: {
              et: "Ma olen sündinud 05.06.1971.",
              en: "Ma olen sündinud 05.06.1971.",
              ru: "Ma olen sündinud 05.06.1971.",
            },
            choices: [
              {
                value: "viiendas juunis aastal tuhat üheksasada seitsekümmend üks.",
                text: {
                  et: "viiendas juunis aastal tuhat üheksasada seitsekümmend üks.",
                  en: "viiendas juunis aastal tuhat üheksasada seitsekümmend üks.",
                  ru: "viiendas juunis aastal tuhat üheksasada seitsekümmend üks.",
                },
              },
              {
                value: "viiendal juunil aastal tuhat üheksasada seitsekümmend üks.",
                text: {
                  et: "viiendal juunil aastal tuhat üheksasada seitsekümmend üks.",
                  en: "viiendal juunil aastal tuhat üheksasada seitsekümmend üks.",
                  ru: "viiendal juunil aastal tuhat üheksasada seitsekümmend üks.",
                },
              },
              {
                value: "viiendale juunile aastal tuhat üheksasada seitsekümmend üks.",
                text: {
                  et: "viiendale juunile aastal tuhat üheksasada seitsekümmend üks.",
                  en: "viiendale juunile aastal tuhat üheksasada seitsekümmend üks.",
                  ru: "viiendale juunile aastal tuhat üheksasada seitsekümmend üks.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "viiendal juunil aastal tuhat üheksasada seitsekümmend üks.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Ma ei taha ... (käima, komandeering)",
            title: {
              et: "Ma ei taha ... (käima, komandeering)",
              en: "Ma ei taha ... (käima, komandeering)",
              ru: "Ma ei taha ... (käima, komandeering)",
            },
            choices: [
              {
                value: "käima komandeeringule.",
                text: {
                  et: "käima komandeeringule.",
                  en: "käima komandeeringule.",
                  ru: "käima komandeeringule.",
                },
              },
              {
                value: "käia komandeeringus.",
                text: {
                  et: "käia komandeeringus.",
                  en: "käia komandeeringus.",
                  ru: "käia komandeeringus.",
                },
              },
              {
                value: "käia komandeeringust.",
                text: {
                  et: "käia komandeeringust.",
                  en: "käia komandeeringust.",
                  ru: "käia komandeeringust.",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "käia komandeeringus.",
          },
        ],
      },
      {
        elements: [
          {
            type: "radiogroup",
            isRequired: true,
            name: "Mida te ... kavatsete teha? (puhkus, aeg)",
            title: {
              et: "Mida te ... kavatsete teha? (puhkus, aeg)",
              en: "Mida te ... kavatsete teha? (puhkus, aeg) (What are you planning to do during your holiday?)",
              ru: "Mida te ... kavatsete teha? (puhkus, aeg)",
            },
            choices: [
              {
                value: "puhkuse ajas",
                text: {
                  et: "puhkuse ajas",
                  en: "puhkuse ajas",
                  ru: "puhkuse ajas",
                },
              },
              {
                value: "puhkuse ajal",
                text: {
                  et: "puhkuse ajal",
                  en: "puhkuse ajal",
                  ru: "puhkuse ajal",
                },
              },
              {
                value: "puhkuse ajale",
                text: {
                  et: "puhkuse ajale",
                  en: "puhkuse ajale",
                  ru: "puhkuse ajale",
                },
              },
              {
                value: "Ei oska vastata.",
                text: {
                  et: "Ei oska vastata.",
                  en: "Ei oska vastata.",
                  ru: "Ei oska vastata.",
                },
              },
            ],
            correctAnswer: "puhkuse ajal",
          },
        ],
      },
    ],
  
    completedHtml:
      "You got {correctAnswers} answers out of {questionCount} questions",
  };
  